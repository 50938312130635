import { FC } from 'react'
import { useFontLoader } from '@ssgat/react-components'

const FontLoader: FC = () => {
    useFontLoader(
        ['Big Shoulders Display', { weight: 700 }],
        ['Open Sans', { weight: 400 }],
        ['Open Sans', { weight: 500 }],
        ['Open Sans', { weight: 600 }],
        ['Open Sans', { weight: 700 }],
        ['pickswise-iconfont']
    )
    return <></>
}

export default FontLoader
