
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UseNavigationTop = {
  navigationTop: AppState['navigationTop'],
  navigationTopLoading: AppState['navigationTopLoading'],
  navigationTopError: AppState['navigationTopError'],
  getNavigationTop: (data:RequestTypes['getNavigationTop'], callbacks?:Callbacks)=>void,
}

export default function useNavigationTop():UseNavigationTop {
  const {
    navigationTop, navigationTopLoading, navigationTopError } = useSelector((state:AppState)=>({
    navigationTop: state.navigationTop,
    navigationTopLoading: state.navigationTopLoading,
    navigationTopError: state.navigationTopError,
  }));
  const dispatch = useDispatch();
  const getNavigationTop = useCallback((data:RequestTypes['getNavigationTop'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getNavigationTop(data, callbacks))
  },[dispatch])
  return {
    navigationTop,
    navigationTopLoading,
    navigationTopError,
    getNavigationTop,
  }
}
