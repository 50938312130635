import { FC, useEffect, useMemo } from 'react'
import { PageContextProvider, useSSGAnalytics } from '@ssgat/react-components'
import { checkCookieConsentLevel, initTrustArcListener } from 'project/trustArc'
import {
    analyticsInstance,
    startSession,
    WithAnalyticsCallbackReturn,
} from 'project/analytics'

// @ts-ignore
import { AnalyticsProvider } from 'use-analytics'
import { shouldDisplaySoftPush } from 'project/braze'
import { useOnAfterRouteChange } from 'project/useOnAfterRouteChange'

export type AnalyticsContainerType = {
    cookies: { [p: string]: string }
    stateCode: string | null
    countryCode: string
    pageAnalytics: WithAnalyticsCallbackReturn
}

const AnalyticsContainer: FC<AnalyticsContainerType> = ({
    cookies,
    children,
    countryCode,
    stateCode,
    pageAnalytics,
}) => {
    const isTrackingAllowed = checkCookieConsentLevel(cookies)
    const { trackPage } = useSSGAnalytics()
    const analytics = useMemo(
        () => analyticsInstance(isTrackingAllowed),
        [isTrackingAllowed]
    )
    const track = analytics.track

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js')
        }

        initTrustArcListener()
    }, [])

    // run logic to check if braze should send permission popup
    useOnAfterRouteChange(() => {
        shouldDisplaySoftPush()
    })
    useEffect(() => {
        if (pageAnalytics) {
            trackPage(pageAnalytics as any)
        }
    }, [pageAnalytics, trackPage])

    useEffect(() => {
        startSession(track, stateCode || '', countryCode)
    }, [track, countryCode, stateCode])

    return (
        <PageContextProvider
            add={{
                country: countryCode,
                state: stateCode || '',
                ...pageAnalytics,
            }}
        >
            <AnalyticsProvider instance={analytics}>
                {children}
            </AnalyticsProvider>
        </PageContextProvider>
    )
}

export default AnalyticsContainer
