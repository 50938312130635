import { AnyAction } from "redux";
import { RequestTypes } from "./state-type";
import { HYDRATE } from 'next-redux-wrapper'

const BaseConstants = {
  LOGIN: "LOGIN",
  LOGIN_LOADED: "LOGIN_LOADED",
  LOGIN_ERROR: "LOGIN_ERROR",
  REFRESH_TOKENS: "REFRESH_TOKENS",

  REGISTER: "REGISTER",
  REGISTER_LOADED: "REGISTER_LOADED",
  REGISTER_ERROR: "REGISTER_ERROR",

  STARTUP: "STARTUP",
  STARTUP_LOADED: "STARTUP_LOADED",
  STARTUP_ERROR: "STARTUP_ERROR",

  LOGOUT: "LOGOUT",
  CLEAR_USER: "CLEAR_USER",
  REFRESH: "REFRESH",

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_LOADED: "GET_PROFILE_LOADED",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_LOADED: "UPDATE_PROFILE_LOADED",
  UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",

  CONFIRM_EMAIL: "CONFIRM_EMAIL",
  CONFIRM_EMAIL_ERROR: "CONFIRM_EMAIL_ERROR",
  CONFIRM_EMAIL_LOADED: "CONFIRM_EMAIL_LOADED",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",
  UPDATE_USER_LOADED: "UPDATE_USER_LOADED",

  SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",

  NEXTJS_HYDRATE: HYDRATE,
};

export interface Callbacks {
  onSuccess?: (data?: any) => void;
  onError?: (data: any, originalError?: any) => void;
}

const BaseActions = {
  login(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGIN,
      data,
      ...callbacks,
    };
  },
  // STARTUP
  startup(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.STARTUP,
      data,
      ...callbacks,
    };
  },
  // REGISTER
  register(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.REGISTER,
      data,
      ...callbacks,
    };
  },
  updateUser(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.UPDATE_USER,
      data,
      ...callbacks,
    };
  },
  confirmEmail(
    data: Record<string, any>,
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.CONFIRM_EMAIL,
      data,
      ...callbacks,
    };
  },
  logout(callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGOUT,
      ...callbacks,
    };
  },

  getProfile(
    data: RequestTypes["getProfile"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PROFILE,
      data,
      ...callbacks,
    };
  },

  updateProfile(
    data: RequestTypes["updateProfile"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.UPDATE_PROFILE,
      data,
      ...callbacks,
    };
  },
  setActiveScreen(name: string, navigator = "root"): AnyAction {
    return {
      type: Actions.SET_ACTIVE_SCREEN,
      index: navigator,
      data: name,
    };
  },
};

// @ts-ignore
export const Actions = (global.Actions = Object.assign({}, BaseConstants, {
  GET_PICKS: "GET_PICKS",
  GET_PICKS_LOADED: "GET_PICKS_LOADED",
  GET_PICKS_ERROR: "GET_PICKS_ERROR",

  GET_BEST_BETS_BY_SPORT: "GET_BEST_BETS_BY_SPORT",
  GET_BEST_BETS_BY_SPORT_LOADED: "GET_BEST_BETS_BY_SPORT_LOADED",
  GET_BEST_BETS_BY_SPORT_ERROR: "GET_BEST_BETS_BY_SPORT_ERROR",

  GET_SPORT_FUTURES: "GET_SPORT_FUTURES",
  GET_SPORT_FUTURES_LOADED: "GET_SPORT_FUTURES_LOADED",
  GET_SPORT_FUTURES_ERROR: "GET_SPORT_FUTURES_ERROR",

  GET_SPORTS: "GET_SPORTS",
  GET_SPORTS_LOADED: "GET_SPORTS_LOADED",
  GET_SPORTS_ERROR: "GET_SPORTS_ERROR",

  UPDATE_SPORT: "UPDATE_SPORT",
  UPDATE_LAST_USED_SPORT: "UPDATE_LAST_USED_SPORT",

  GET_NEXT_UP: "GET_NEXT_UP",
  GET_NEXT_UP_LOADED: "GET_NEXT_UP_LOADED",
  GET_NEXT_UP_ERROR: "GET_NEXT_UP_ERROR",

  GET_NEXT_UP_BY_SPORT: "GET_NEXT_UP_BY_SPORT",
  GET_NEXT_UP_BY_SPORT_LOADED: "GET_NEXT_UP_BY_SPORT_LOADED",
  GET_NEXT_UP_BY_SPORT_ERROR: "GET_NEXT_UP_BY_SPORT_ERROR",

  GET_PARLAYS: "GET_PARLAYS",
  GET_PARLAYS_LOADED: "GET_PARLAYS_LOADED",
  GET_PARLAYS_ERROR: "GET_PARLAYS_ERROR",

  GET_SPORT_PARLAYS: "GET_SPORT_PARLAYS",
  GET_SPORT_PARLAYS_LOADED: "GET_SPORT_PARLAYS_LOADED",
  GET_SPORT_PARLAYS_ERROR: "GET_SPORT_PARLAYS_ERROR",

  GET_PREDICTION_PARLAYS: "GET_PREDICTION_PARLAYS",
  GET_PREDICTION_PARLAYS_LOADED: "GET_PREDICTION_PARLAYS_LOADED",
  GET_PREDICTION_PARLAYS_ERROR: "GET_PREDICTION_PARLAYS_ERROR",

  GET_PREDICTIONS: "GET_PREDICTIONS",
  GET_PREDICTIONS_LOADED: "GET_PREDICTIONS_LOADED",
  GET_PREDICTIONS_ERROR: "GET_PREDICTIONS_ERROR",

  GET_SPORT_PREDICTIONS: "GET_SPORT_PREDICTIONS",
  GET_SPORT_PREDICTIONS_LOADED: "GET_SPORT_PREDICTIONS_LOADED",
  GET_SPORT_PREDICTIONS_ERROR: "GET_SPORT_PREDICTIONS_ERROR",

  GET_STATES: "GET_STATES",
  GET_STATES_LOADED: "GET_STATES_LOADED",
  GET_STATES_ERROR: "GET_STATES_ERROR",

  GET_PROMO_CODES: "GET_PROMO_CODES",
  GET_PROMO_CODES_LOADED: "GET_PROMO_CODES_LOADED",
  GET_PROMO_CODES_ERROR: "GET_PROMO_CODES_ERROR",

  GET_STATE_PROMO_CODES: "GET_STATE_PROMO_CODES",
  GET_STATE_PROMO_CODES_LOADED: "GET_STATE_PROMO_CODES_LOADED",
  GET_STATE_PROMO_CODES_ERROR: "GET_STATE_PROMO_CODES_ERROR",

  GET_ACTIVE_PROMO_CODE: "GET_ACTIVE_PROMO_CODE",
  GET_ACTIVE_PROMO_CODE_LOADED: "GET_ACTIVE_PROMO_CODE_LOADED",
  GET_ACTIVE_PROMO_CODE_ERROR: "GET_ACTIVE_PROMO_CODE_ERROR",

  GET_SPORT_PREDICTIONS_PICKS: "GET_SPORT_PREDICTIONS_PICKS",
  GET_SPORT_PREDICTIONS_PICKS_LOADED: "GET_SPORT_PREDICTIONS_PICKS_LOADED",
  GET_SPORT_PREDICTIONS_PICKS_ERROR: "GET_SPORT_PREDICTIONS_PICKS_ERROR",

  GET_INDIVIDUAL_NEXT_GAMES: "GET_INDIVIDUAL_NEXT_GAMES",
  GET_INDIVIDUAL_NEXT_GAMES_LOADED: "GET_INDIVIDUAL_NEXT_GAMES_LOADED",
  GET_INDIVIDUAL_NEXT_GAMES_ERROR: "GET_INDIVIDUAL_NEXT_GAMES_ERROR",

  GET_PREDICTION: "GET_PREDICTION",
  GET_PREDICTION_LOADED: "GET_PREDICTION_LOADED",
  GET_PREDICTION_ERROR: "GET_PREDICTION_ERROR",

  SET_FLAGSMITH: "SET_FLAGSMITH",
  SET_LINK_HANDLING_DATA: "SET_LINK_HANDLING_DATA",

  GET_SIGNUP_LINK: "GET_SIGNUP_LINK",
  GET_SIGNUP_LINK_LOADED: "GET_SIGNUP_LINK_LOADED",
  GET_SIGNUP_LINK_ERROR: "GET_SIGNUP_LINK_ERROR",

  GET_HOME_NEWS: "GET_HOME_NEWS",
  GET_HOME_NEWS_LOADED: "GET_HOME_NEWS_LOADED",
  GET_HOME_NEWS_ERROR: "GET_HOME_NEWS_ERROR",

  GET_FEATURED_NEWS: "GET_FEATURED_NEWS",
  GET_FEATURED_NEWS_LOADED: "GET_FEATURED_NEWS_LOADED",
  GET_FEATURED_NEWS_ERROR: "GET_FEATURED_NEWS_ERROR",

  GET_RELATED_NEWS: "GET_RELATED_NEWS",
  GET_RELATED_NEWS_LOADED: "GET_RELATED_NEWS_LOADED",
  GET_RELATED_NEWS_ERROR: "GET_RELATED_NEWS_ERROR",

  'GET_SPORT_NEWS': 'GET_SPORT_NEWS',
  'GET_SPORT_NEWS_LOADED': 'GET_SPORT_NEWS_LOADED',
  'GET_SPORT_NEWS_ERROR': 'GET_SPORT_NEWS_ERROR',

  GET_NEWS_ARTICLE: "GET_NEWS_ARTICLE",
  GET_NEWS_ARTICLE_LOADED: "GET_NEWS_ARTICLE_LOADED",
  GET_NEWS_ARTICLE_ERROR: "GET_NEWS_ARTICLE_ERROR",

  'SCREEN_ERROR': 'SCREEN_ERROR',

  'GET_NAVIGATION_TOP': 'GET_NAVIGATION_TOP',
  'GET_NAVIGATION_TOP_LOADED': 'GET_NAVIGATION_TOP_LOADED',
  'GET_NAVIGATION_TOP_ERROR': 'GET_NAVIGATION_TOP_ERROR',

  'GET_NAVIGATION_HAMBURGER': 'GET_NAVIGATION_HAMBURGER',
  'GET_NAVIGATION_HAMBURGER_LOADED': 'GET_NAVIGATION_HAMBURGER_LOADED',
  'GET_NAVIGATION_HAMBURGER_ERROR': 'GET_NAVIGATION_HAMBURGER_ERROR',

  'GET_CONTENT': 'GET_CONTENT',
  'GET_CONTENT_LOADED': 'GET_CONTENT_LOADED',
  'GET_CONTENT_ERROR': 'GET_CONTENT_ERROR',

  'GET_FEATURED': 'GET_FEATURED',
  'GET_FEATURED_LOADED': 'GET_FEATURED_LOADED',
  'GET_FEATURED_ERROR': 'GET_FEATURED_ERROR',

  'GET_PROMO_CODES_SIDEBAR': 'GET_PROMO_CODES_SIDEBAR',
  'GET_PROMO_CODES_SIDEBAR_LOADED': 'GET_PROMO_CODES_SIDEBAR_LOADED',
  'GET_PROMO_CODES_SIDEBAR_ERROR': 'GET_PROMO_CODES_SIDEBAR_ERROR',

  'GET_STORYDISKS': 'GET_STORYDISKS',
  'GET_STORYDISKS_LOADED': 'GET_STORYDISKS_LOADED',
  'GET_STORYDISKS_ERROR': 'GET_STORYDISKS_ERROR',

  'SET_CURRENT_STORYBOOK_OFFER': 'SET_CURRENT_STORYBOOK_OFFER',
  'UPDATE_STORY_DISK_VIEWED_OFFERS': 'UPDATE_STORY_DISK_VIEWED_OFFERS',
  'BULK_UPDATE_STORY_DISK_VIEWED_OFFERS': 'BULK_UPDATE_STORY_DISK_VIEWED_OFFERS',

  'GET_HYPOMETER': 'GET_HYPOMETER',
  'GET_HYPOMETER_LOADED': 'GET_HYPOMETER_LOADED',
  'GET_HYPOMETER_ERROR': 'GET_HYPOMETER_ERROR',

  'GET_PREDICTIONS_SELECT': 'GET_PREDICTIONS_SELECT',
  'GET_PREDICTIONS_SELECT_LOADED': 'GET_PREDICTIONS_SELECT_LOADED',
  'GET_PREDICTIONS_SELECT_ERROR': 'GET_PREDICTIONS_SELECT_ERROR',

  'GET_TERMS': 'GET_TERMS',
  'GET_TERMS_LOADED': 'GET_TERMS_LOADED',
  'GET_TERMS_ERROR': 'GET_TERMS_ERROR',

  'GET_EVENTS': 'GET_EVENTS',
  'GET_EVENTS_LOADED': 'GET_EVENTS_LOADED',
  'GET_EVENTS_ERROR': 'GET_EVENTS_ERROR',

  'GET_TEAM_GUIDES': 'GET_TEAM_GUIDES',
  'GET_TEAM_GUIDES_LOADED': 'GET_TEAM_GUIDES_LOADED',
  'GET_TEAM_GUIDES_ERROR': 'GET_TEAM_GUIDES_ERROR',

  'GET_PREDICTIONS_ENHANCED': 'GET_PREDICTIONS_ENHANCED',
  'GET_PREDICTIONS_ENHANCED_LOADED': 'GET_PREDICTIONS_ENHANCED_LOADED',
  'GET_PREDICTIONS_ENHANCED_ERROR': 'GET_PREDICTIONS_ENHANCED_ERROR',

  'GET_GROUPED_PROMO_CODES': 'GET_GROUPED_PROMO_CODES',
  'GET_GROUPED_PROMO_CODES_LOADED': 'GET_GROUPED_PROMO_CODES_LOADED',
  'GET_GROUPED_PROMO_CODES_ERROR': 'GET_GROUPED_PROMO_CODES_ERROR',

  'GET_PICK_GRID': 'GET_PICK_GRID',
  'GET_PICK_GRID_LOADED': 'GET_PICK_GRID_LOADED',
  'GET_PICK_GRID_ERROR': 'GET_PICK_GRID_ERROR',

// END OF ACTION_STRINGS
}));

// @ts-ignore
export const AppActions = (global.AppActions = Object.assign({}, BaseActions, {
  getPicks(
    data: RequestTypes["getPicks"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PICKS,
      data,
      ...callbacks,
    };
  },

  getBestBetsBySport(
    data: RequestTypes["getBestBetsBySport"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_BEST_BETS_BY_SPORT,
      data,
      ...callbacks,
    };
  },

  getSportFutures(
    data: RequestTypes["getSportFutures"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SPORT_FUTURES,
      data,
      ...callbacks,
    };
  },

  getSports(
    data: RequestTypes["getSports"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SPORTS,
      data,
      ...callbacks,
    };
  },

  updateSport(
    data: RequestTypes["updateSport"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.UPDATE_SPORT,
      data,
      ...callbacks,
    };
  },

  updateLastUsedSport(
    data: RequestTypes["updateLastUsedSport"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.UPDATE_LAST_USED_SPORT,
      data,
      ...callbacks,
    };
  },

  getNextUp(
    data: RequestTypes["getNextUp"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_NEXT_UP,
      data,
      ...callbacks,
    };
  },

  getNextUpBySport(
    data: RequestTypes["getNextUpBySport"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_NEXT_UP_BY_SPORT,
      data,
      ...callbacks,
    };
  },

  getPredictions(
    data: RequestTypes["getPredictions"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PREDICTIONS,
      data,
      ...callbacks,
    };
  },

  getSportPredictions(
    data: RequestTypes["getSportPredictions"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SPORT_PREDICTIONS,
      data,
      ...callbacks,
    };
  },

  getSportPredictionsPicks(
    data: RequestTypes["getSportPredictionsPicks"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SPORT_PREDICTIONS_PICKS,
      data,
      ...callbacks,
    };
  },

  getStates(
    data: RequestTypes["getStates"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_STATES,
      data,
      ...callbacks,
    };
  },

  getPromoCodes(
    data: RequestTypes["getPromoCodes"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PROMO_CODES,
      data,
      ...callbacks,
    };
  },

  getStatePromoCodes(
    data: RequestTypes["getStatePromoCodes"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_STATE_PROMO_CODES,
      data,
      ...callbacks,
    };
  },

  getActivePromoCode(
    data: RequestTypes["getActivePromoCode"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_ACTIVE_PROMO_CODE,
      data,
      ...callbacks,
    };
  },

  getParlays(
    data: RequestTypes["getParlays"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PARLAYS,
      data,
      ...callbacks,
    };
  },

  getSportParlays(
    data: RequestTypes["getSportParlays"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SPORT_PARLAYS,
      data,
      ...callbacks,
    };
  },

  getPredictionParlays(
    data: RequestTypes["getPredictionParlays"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PREDICTION_PARLAYS,
      data,
      ...callbacks,
    };
  },

  getIndividualNextGames(
    data: RequestTypes["getIndividualNextGames"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_INDIVIDUAL_NEXT_GAMES,
      data,
      ...callbacks,
    };
  },

  getPrediction(
    data: RequestTypes["getPrediction"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_PREDICTION,
      data,
      ...callbacks,
    };
  },

  setFlagsmith(
    data: RequestTypes["setFlagsmith"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.SET_FLAGSMITH,
      data,
      ...callbacks,
    };
  },

  setLinkHandlingData(
    data: RequestTypes["setLinkHandlingData"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.SET_LINK_HANDLING_DATA,
      data,
      ...callbacks,
    };
  },

  getSignupLink(
    data: RequestTypes["getSignupLink"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_SIGNUP_LINK,
      data,
      ...callbacks,
    };
  },

  getHomeNews(
    data: RequestTypes["getHomeNews"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_HOME_NEWS,
      data,
      ...callbacks,
    };
  },

  getFeaturedNews(
    data: RequestTypes["getFeaturedNews"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_FEATURED_NEWS,
      data,
      ...callbacks,
    };
  },

  getRelatedNews(
    data: RequestTypes["getRelatedNews"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_RELATED_NEWS,
      data,
      ...callbacks,
    };
  },

  getSportNews(data: RequestTypes['getSportNews'], callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_SPORT_NEWS,
      data,
      ...callbacks,
    };
  },

  getNewsArticle(
    data: RequestTypes["getNewsArticle"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.GET_NEWS_ARTICLE,
      data,
      ...callbacks,
    };
  },

  screenError(data: RequestTypes['screenError'], callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.SCREEN_ERROR,
      data,
      ...callbacks,
    };
  },

  getStoryDisks(data: RequestTypes['getStoryDisks'],callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_STORYDISKS,
      data,
      ...callbacks,
    };
  },

  setCurrentStorybookOffer(
    data: RequestTypes["setCurrentStorybookOffer"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.SET_CURRENT_STORYBOOK_OFFER,
      data,
      ...callbacks,
    };
  },

  updateStoryDiskViewedOffers(
    data: RequestTypes["updateStoryDiskViewedOffers"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.UPDATE_STORY_DISK_VIEWED_OFFERS,
      data,
      ...callbacks,
    };
  },

  bulkUpdateStoryDiskViewedOffers(
    data: RequestTypes["bulkUpdateStoryDiskViewedOffers"],
    callbacks: Callbacks = {}
  ): AnyAction {
    return {
      type: Actions.BULK_UPDATE_STORY_DISK_VIEWED_OFFERS,
      data,
      ...callbacks,
    };
  },

  getNavigationTop(data:RequestTypes['getNavigationTop'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_NAVIGATION_TOP,
      data,
      ...callbacks,
    };
  },


  getNavigationHamburger(data:RequestTypes['getNavigationHamburger'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_NAVIGATION_HAMBURGER,
      data,
      ...callbacks,
    };
  },

  getContent(data:RequestTypes['getContent'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_CONTENT,
      data,
      ...callbacks,
    };
  },

  getFeatured(data:RequestTypes['getFeatured'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_FEATURED,
      data,
      ...callbacks,
    };
  },

  getPromoCodesSidebar(data:RequestTypes['getPromoCodesSidebar'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_PROMO_CODES_SIDEBAR,
      data,
      ...callbacks,
    };
  },

  getHypometer(data:RequestTypes['getHypometer'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_HYPOMETER,
      data,
      ...callbacks,
    };
  },


  getTerms(data: RequestTypes['getTerms'], callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_TERMS,
      data,
      ...callbacks,
    };
  },

  getPredictionsSelect(data:RequestTypes['getPredictionsSelect'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_PREDICTIONS_SELECT,
      data,
      ...callbacks,
    };
  },

  getEvents(data:RequestTypes['getEvents'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_EVENTS,
      data,
      ...callbacks,
    };
  },

  getTeamGuides(data:RequestTypes['getTeamGuides'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_TEAM_GUIDES,
      data,
      ...callbacks,
    };
  },


  getPredictionsEnhanced(data:RequestTypes['getPredictionsEnhanced'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_PREDICTIONS_ENHANCED,
      data,
      ...callbacks,
    };
  },


  getGroupedPromoCodes(data:RequestTypes['getGroupedPromoCodes'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_GROUPED_PROMO_CODES,
      data,
      ...callbacks,
    };
  },

  
  getPickGrid(data:RequestTypes['getPickGrid'], callbacks:Callbacks={}):AnyAction {
    return {
      type: Actions.GET_PICK_GRID,
      data,
      ...callbacks,
    };
  },

// END OF APP_ACTIONS
}));
