import { Constants } from '@ssgat/common/utils/constants'
import { errorHandler } from '@ssgat/common/utils/errorHandler'
import { Project, ProjectType } from '@ssgat/common/project'
import { IncomingMessage } from 'http'
import acceptLanguageParser from 'accept-language-parser'
import { noop } from 'lodash'
import Strings from './localization'
import { APIType } from '@ssgat/common/api-type'
import getConfig from 'next/config'

const {
    publicRuntimeConfig: { NEXT_PUBLIC_API_URL },
} = getConfig()

interface WebAPI extends APIType {
    getLocaleFromRequest: (req?: IncomingMessage) => any
    alias: any
}

const API: WebAPI = {
    isMobile: () => false,
    ajaxHandler(type: string, e?: { message: string }) {
        return {
            type,
            error: errorHandler({
                defaultErrorMessage: Strings.defaultErrorMessage,
                gatewayTimeoutError: Strings.gatewayTimeoutError,
            })(e),
        }
    },
    middlewares: [],
    getLocaleFromRequest(req?: IncomingMessage) {
        if (req?.headers?.['accept-language']) {
            const acceptLanguages = acceptLanguageParser.parse(
                req.headers['accept-language']
            )

            if (Array.isArray(acceptLanguages)) {
                return acceptLanguages[0]?.code || Constants.defaultLocale
            }
        }
        return Constants.defaultLocale
    },
    trackPage: noop,
    alias: noop,
    identify: noop,
    log(namespace: keyof ProjectType['logs'], ...args: any[]) {
        if (Project.logs[namespace]) {
            // eslint-disable-next-line no-console
            console.log.apply(this, [namespace, ...args])
        }
    },
    getAPIBaseUrl: () => NEXT_PUBLIC_API_URL || '',
}

export { API }
