import { createWrapper } from 'next-redux-wrapper'
import createStore from '@ssgat/common/store'
import { Store } from 'redux'
import { AppState } from '@ssgat/common/state-type'
import { Project } from '@ssgat/common/project'

const makeStore = () => {
    return createStore(undefined, true, true)
}

export const nextReduxWrapper = createWrapper<Store<AppState>>(makeStore, {
    debug: Project.logs.DISPATCHER,
})
