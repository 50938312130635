import { FC, useContext, useEffect } from 'react'
import { AppContext } from '@ssgat/react-components'

type ComponentType = {
    pageUrl: string
}

const StateTransfer: FC<ComponentType> = ({ pageUrl }) => {
    const { setState } = useContext(AppContext)
    useEffect(() => {
        // @ts-ignore todo: fails types, original file was .js
        setState((prev) => ({ ...prev, url: pageUrl }))
    }, [pageUrl, setState])
    return <></>
}

export default StateTransfer
