import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "../project";
import { TPick } from "../state-type";
import BaseUtils from "./base/_utils";
require("dayjs/locale/es");
dayjs.extend(utc);
dayjs.extend(timezone);

const startTimeFormat = new Intl.DateTimeFormat("en-GB", {
  hour12: true,
  timeZone: "America/New_York",
  hour: "numeric",
  minute: "2-digit",
});

const baseTimeStampFormat: Intl.DateTimeFormatOptions = {
  hour12: false,
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  formatMatcher: "basic",
};

const timeStampFormatUs = new Intl.DateTimeFormat("en-GB", {
  ...baseTimeStampFormat,
  timeZone: "America/New_York",
});

const timeStampFormatUk = new Intl.DateTimeFormat("en-GB", {
  ...baseTimeStampFormat,
  timeZone: "Europe/London",
});

export const Utils = {
  ...BaseUtils,
  formatStartTime: (startTime: string) => {
    const date = dayjs(startTime).toDate();
    return startTimeFormat.format(date).toUpperCase().concat(" ET");
  },
  getTimestamps: () => ({
    uk_timestamp: timeStampFormatUk.format(Date.now()),
    us_timestamp: timeStampFormatUs.format(Date.now()),
  }),
  formatStartTimeDay: (startTime: string) => {
    const dt = dayjs(startTime);
    const isToday = dayjs().diff(dt, "days") === 0;
    return isToday ? "Today" : dt.format("ddd MMM DD");
  },
  filterTags: (
    tags: TPick["tags"],
    whitelist: string[],
    blacklist: string[]
  ) => {
    return tags?.filter((tag) => {
      if (
        (!!blacklist && blacklist.includes(tag.type)) ||
          (!!whitelist && !whitelist.includes(tag.type))
      ) {
        return false;
      }

      return true
    })
  }
}

