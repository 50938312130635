import chalk from 'chalk'

export const registerBraze = () => {
    if (typeof window !== 'undefined' && window.appboy) {
        brazeHandleInAppMessages()
        shouldDisplaySoftPush()
    } else {
        console.log(chalk.blueBright('braze'), '-', 'Braze not loaded')
    }
}

const brazeHandleInAppMessages = () => {
    window.appboy.subscribeToInAppMessage((message) => {
        if (message != null) {
            let shouldDisplay = true
            // Read the key/value pair for msg-id
            const msgId = message.extras['msg-id']

            // If this is our push primer message
            if (msgId == 'push-primer') {
                // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
                // has already granted/blocked permission
                if (
                    !window.appboy.isPushSupported() ||
                    window.appboy.isPushPermissionGranted() ||
                    window.appboy.isPushBlocked()
                ) {
                    shouldDisplay = false
                }
                // add click handler for 'yes c2a'
                message.buttons[1].subscribeToClickedEvent(() => {
                    window.appboy.logCustomEvent('soft-push-accepted')
                    // prompt browser to accept notifications
                    window.appboy.registerAppboyPushMessages()
                })

                // add click handler for 'no c2a'
                message.buttons[0].subscribeToClickedEvent(() => {
                    window.appboy.logCustomEvent('soft-push-decline')
                })

                // Display the message
                if (shouldDisplay) {
                    window.appboy.logCustomEvent('soft-push-impression')
                    window.appboy.display.showInAppMessage(message)
                }
                // handle default behavo
            } else {
                window.appboy.display.showInAppMessage(message)
            }
        }

        return message
    })
}

// used for setting the localstorage vars we need
const BrazeConfig = {
    // index relates to stage
    1: {
        offset: 0, // time offset
        views: 8, // views offset
    },
    2: {
        offset: 7 * 24 * 60 * 60 * 1000,
        views: 4,
    },
    3: {
        offset: 5 * 24 * 60 * 60 * 1000,
        views: 4,
    },
}

const getBrazeStage = () => {
    let brazeStage = localStorage.getItem('braze_stage')
    if (brazeStage === null) {
        brazeStage = 1
        localStorage.setItem('braze_stage', brazeStage)
    }
    return brazeStage
}

const getBrazeTimestamp = () => {
    let brazeTimestamp = localStorage.getItem('braze_timestamp')
    if (brazeTimestamp === null) {
        brazeTimestamp = new Date().getTime()
        localStorage.setItem('braze_timestamp', brazeTimestamp)
    }
    return brazeTimestamp
}

const getBrazeViewCount = () => {
    let brazeViewCount = localStorage.getItem('braze_view_count')
    if (brazeViewCount === null) {
        brazeViewCount = 1
        localStorage.setItem('braze_view_count', brazeViewCount)
    }
    return brazeViewCount
}

const getBrazeViewThreshold = () => {
    const brazeStage = getBrazeStage()
    if (BrazeConfig[brazeStage] && BrazeConfig[brazeStage].views) {
        return BrazeConfig[brazeStage].views
    }
    // return stage 1 if not set
    return BrazeConfig[1].views
}

export const shouldDisplaySoftPush = () => {
    // return early if braze not initalised/adblocked
    if (typeof window === 'undefined' || !window.appboy) {
        console.log('Braze not loaded')
        return
    }

    // exit from flow if no more braze stages
    if (BrazeConfig[parseInt(getBrazeStage())] === undefined) {
        return
    }

    let brazeViewCount = getBrazeViewCount()

    const currentTimestamp = new Date().getTime()

    // we've passed the previous date threshold, so we can increment the view counter and check if can trigger delivery
    if (currentTimestamp > getBrazeTimestamp()) {
        brazeViewCount++
        localStorage.setItem('braze_view_count', brazeViewCount)
    }

    // we've passed the date threshold and view count requird
    if (
        brazeViewCount >= getBrazeViewThreshold() &&
        currentTimestamp > getBrazeTimestamp()
    ) {
        // trigger appboy event that will signal message to be delivered.
        window.appboy.logCustomEvent('prime-for-push')

        const brazeStage = parseInt(getBrazeStage()) + 1
        localStorage.setItem('braze_stage', brazeStage)
        // reset views
        localStorage.setItem('braze_view_count', 0)

        // set new datetime offset
        if (BrazeConfig[brazeStage] && BrazeConfig[brazeStage].offset) {
            const newBrazeTimestamp = new Date(
                new Date().getTime() + parseInt(BrazeConfig[brazeStage].offset)
            ).getTime()
            localStorage.setItem('braze_timestamp', newBrazeTimestamp)
        }
    }
}
