const DEV = global.__DEV__
  ? global.__DEV__
  : typeof __DEV__ === "undefined"
    ? false
    : __DEV__;

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  STORYBOOK: DEV && false,
  simulate: !DEV
    ? {}
    : {
      AGE_GATE: false,
      CONFIRM_EMAIL: false,
      FORCE_LANGUAGE: '',
      FORCE_PAGE: null,
      FORCE_SUB_PAGE: null,
    },
  statusBarHeight: 0, // gets set on launch
  pages: {
    NOT_FOUND: "Not Found",
    HOME_PAGE: "Home",
  },
  terms: "https://www.pickswise.com/terms-conditions/",
  privacy: "https://www.pickswise.com/privacy-policy/",
  defaultLocale: 'en',
}
