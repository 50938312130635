import { all, put, takeLatest } from "redux-saga/effects";
import { getApi } from './api/api';
import { Actions } from "./app-actions";
import {
  AppDataItem,
  AppState,
  RequestTypes,
  ServerDataArray,
  TActivePromoCode,
  TArticle,
  TOtherGamesResponse,
  TPick,
  TPrediction
} from "./state-type";
import { getAction, IAction } from "./utils/saga-helpers";
import _data from "./utils/_data";

// Called when the application starts up, if using SSR this is done in the server
export function* startup(action: IAction<RequestTypes['startup']>) {
  try {
    const { ...rest } = action.data || {};
    const token = action.data?.token;

    if (token) {
      _data.setToken(token);
      // set the user
    }

    const isOnline = typeof navigator === "undefined" ? true : navigator.onLine;
    const data = { ready: true, isOnline, ...rest };
    yield put({
      type: Actions.STARTUP_LOADED,
      data: { ready: true, isOnline, ...rest },
    });
    if (action.onSuccess) {
      action.onSuccess(data);
    }
  } catch (e) {
    yield put(getApi().ajaxHandler(Actions.STARTUP_ERROR, e));
    if (action?.onError) {
      action.onError({ error: e });
    }
  }
}

export function* getPicks(action: IAction<RequestTypes["getPicks"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}picks`, "GET_PICKS");
}

export function* getBestBetsBySport(action: IAction<RequestTypes["getBestBetsBySport"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}picks`, "GET_BEST_BETS_BY_SPORT");
}

export function* getSportFutures(action: IAction<RequestTypes["getSportPredictions"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}picks`, "GET_SPORT_FUTURES");
}

export function* getSports(action: IAction<RequestTypes["getSports"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}sports`, "GET_SPORTS");
}

export function* getNextUp(action: IAction<RequestTypes["getNextUp"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}predictions/next`, "GET_NEXT_UP");
}

export function* getNextUpBySport(action: IAction<RequestTypes["getNextUpBySport"]>) {
  yield getAction<{ sports: string }, AppState['nextUpBySport']>
  (
    {
      ...action,
      data: {
        sports: action.data?.sport,
      },
    },
    `${getApi().getAPIBaseUrl()}predictions/next`,
    "GET_NEXT_UP_BY_SPORT"
  );
}

export function* getPredictions(action: IAction<RequestTypes["getPredictions"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}predictions`, "GET_PREDICTIONS");
}

export function* getSportPredictions(action: IAction<RequestTypes["getSportPredictions"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}predictions`, "GET_SPORT_PREDICTIONS");
}

export function* getSportPredictionsPicks(action: IAction<RequestTypes["getSportPredictionsPicks"]>) {
  yield getAction(
    action,
    `${getApi().getAPIBaseUrl()}predictions`,
    "GET_SPORT_PREDICTIONS_PICKS"
  );
}

export function* getParlays(action: IAction<RequestTypes["getParlays"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}parlays`, "GET_PARLAYS");
}

export function* getSportParlays(action: IAction<RequestTypes["getSportParlays"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}parlays`, "GET_SPORT_PARLAYS");
}
export function* getPredictionParlays(action: IAction<RequestTypes["getPredictionParlays"]>) {
  yield getAction(
    action,
    `${getApi().getAPIBaseUrl()}parlays/related`,
    "GET_PREDICTION_PARLAYS"
  );
}

export function* getOtherGames(action: IAction<RequestTypes["getOtherGames"]>) {
  yield getAction<RequestTypes['getOtherGames'], TOtherGamesResponse, AppState['otherGames']>
  (
    action,
    `${getApi().getAPIBaseUrl()}predictions/select`,
    "GET_OTHER_GAMES",
    false,
    (res) => {
      let picks: TPick[] = [];
      // this endpoint returns [{Predictions:[]}], flatten the data
      if (res?.data?.length) {
        res?.data.map((v) => (picks = picks.concat(v.predictions)));
      }
      return { data: picks };
    }
  );
}

export function* getIndividualNextGames(action: IAction<RequestTypes["getIndividualNextGames"]>) {
  yield getAction(
    action,
    `${getApi().getAPIBaseUrl()}predictions/related`,
    "GET_INDIVIDUAL_NEXT_GAMES",
    false,
  );
}

export function* getPrediction(action: IAction<RequestTypes["getPrediction"]>) {
  yield getAction<RequestTypes['getPrediction'],
  ServerDataArray<TPrediction>,
  AppDataItem<TPrediction>
  >
  (
    action,
    `${getApi().getAPIBaseUrl()}predictions/single`,
    "GET_PREDICTION",
    false,
    (res) => {
      return { data: res?.data?.[0] };
    }
  );
}

export function* getStates(action: IAction<RequestTypes["getStates"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}states`, "GET_STATES");
}

export function* getPromoCodes(action: IAction<RequestTypes["getStatePromoCodes"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}promo-codes`, "GET_PROMO_CODES");
}

export function* getStatePromoCodes(action: IAction<RequestTypes["getStatePromoCodes"]>) {
  yield getAction(action,`${getApi().getAPIBaseUrl()}promo-codes`,"GET_STATE_PROMO_CODES");
}

export function* getActivePromoCode(action: IAction<RequestTypes["getActivePromoCode"]>) {
  yield getAction<
  RequestTypes['getActivePromoCode'],
  ServerDataArray<TActivePromoCode>,
  AppDataItem<TActivePromoCode>
  >(
    action,
    `${getApi().getAPIBaseUrl()}promo-codes/link`,
    "GET_ACTIVE_PROMO_CODE",
    false,
    (res) => {
      return {
        data: {
          ...res?.data?.[0],
          id: action.data.id
        }
      };
    },
    true
  );
}

export function* getSignupLink(action: IAction<RequestTypes["getSignupLink"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}promo-codes/link`, "GET_SIGNUP_LINK");
}

export function* getHomeNews(action: IAction<RequestTypes["getHomeNews"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}news/home`, "GET_HOME_NEWS", false);
}

export function* getFeaturedNews(action: IAction<RequestTypes["getFeaturedNews"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}news/featured`, "GET_FEATURED_NEWS");
}

export function* getRelatedNews(action: IAction<RequestTypes["getRelatedNews"]>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}news/related`, "GET_RELATED_NEWS");
}

export function* getNewsArticle(action: IAction<RequestTypes["getNewsArticle"]>) {
  yield getAction<
  RequestTypes["getNewsArticle"],
  ServerDataArray<TArticle>,
  AppDataItem<TArticle>
  >(
    action,
    `${getApi().getAPIBaseUrl()}news/single`,
    "GET_NEWS_ARTICLE",
    false,
    (res) => {
      return { data: { ...res?.data?.[0] } };
    },
    true
  );
}

export function* getSportNews(action: IAction<RequestTypes['getSportNews']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}news`, 'GET_SPORT_NEWS', false);
}

export function* getStoryDisks(action: IAction<RequestTypes['getStoryDisks']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}story-disks`, 'GET_STORYDISKS');
}

export function* getNavigationTop(action: IAction<RequestTypes['getNavigationTop']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}navigation/top`, 'GET_NAVIGATION_TOP');
}

export function* getNavigationHamburger(action: IAction<RequestTypes['getNavigationHamburger']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}navigation/hamburger`, 'GET_NAVIGATION_HAMBURGER');
}

export function* getContent(action: IAction<RequestTypes['getContent']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}content`, 'GET_CONTENT');
}

export function* getFeatured(action: IAction<RequestTypes['getFeatured']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}featured`, 'GET_FEATURED');
}

export function* getPromoCodesSidebar(action: IAction<RequestTypes['getPromoCodesSidebar']>) {
  yield getAction(action, `${getApi().getAPIBaseUrl()}promo-codes/sidebar`, 'GET_PROMO_CODES_SIDEBAR');
}
export function* getHypometer(action:IAction<RequestTypes['getHypometer']>) {
  yield getAction<RequestTypes['getHypometer'], AppState['hypometer']>
  (action, `${getApi().getAPIBaseUrl()}hypometer`, 'GET_HYPOMETER');
}

export function* getPredictionsSelect(action:IAction<RequestTypes['getPredictionsSelect']>) {
  yield getAction<RequestTypes['getPredictionsSelect'], AppState['predictionsSelect']>
  (action, `${getApi().getAPIBaseUrl()}predictions/select`, 'GET_PREDICTIONS_SELECT');
}

export function* getTerms(action: IAction<RequestTypes['getTerms']>) {
  yield getAction<
  RequestTypes['getTerms'],
  AppState['terms']
  >
  // need to pass whole url here. should GET_TERMS be set somewhere?? like GET_NEWS_ARTICLE?
  (action, `${getApi().getAPIBaseUrl()}content`, 'GET_TERMS');
}

export function* getEvents(action:IAction<RequestTypes['getEvents']>) {
  yield getAction<RequestTypes['getEvents'], AppState['events']>
  (action, `${getApi().getAPIBaseUrl()}events`, 'GET_EVENTS');
}

export function* getTeamGuides(action:IAction<RequestTypes['getTeamGuides']>) {
  yield getAction<RequestTypes['getTeamGuides'], AppState['teamGuides']>
  (action, `${getApi().getAPIBaseUrl()}team-guides`, 'GET_TEAM_GUIDES');
}

export function* getPredictionsEnhanced(action:IAction<RequestTypes['getPredictionsEnhanced']>) {
  yield getAction<RequestTypes['getPredictionsEnhanced'], AppState['predictionsEnhanced']>
  (action, `${getApi().getAPIBaseUrl()}predictions/enhanced`, 'GET_PREDICTIONS_ENHANCED');
}

export function* getGroupedPromoCodes(action:IAction<RequestTypes['getGroupedPromoCodes']>) {
  yield getAction<RequestTypes['getGroupedPromoCodes'], AppState['groupedPromoCodes']>
  (action, `${getApi().getAPIBaseUrl()}promo-codes`, 'GET_GROUPED_PROMO_CODES');
}

export function* getPickGrid(action:IAction<RequestTypes['getPickGrid']>) {
  yield getAction<RequestTypes['getPickGrid'], AppState['pickGrid']>
  (action, `${getApi().getAPIBaseUrl()}pick-grid`, 'GET_PICK_GRID');
}

// END OF YIELDS

function* rootSaga() {
  yield all([
    takeLatest(Actions.STARTUP, startup),
    takeLatest(Actions.GET_PICKS, getPicks),
    takeLatest(Actions.GET_BEST_BETS_BY_SPORT, getBestBetsBySport),
    takeLatest(Actions.GET_SPORT_FUTURES, getSportFutures),
    takeLatest(Actions.GET_SPORTS, getSports),
    takeLatest(Actions.GET_NEXT_UP, getNextUp),
    takeLatest(Actions.GET_NEXT_UP_BY_SPORT, getNextUpBySport),
    takeLatest(Actions.GET_PREDICTIONS, getPredictions),
    takeLatest(Actions.GET_SPORT_PREDICTIONS, getSportPredictions),
    takeLatest(Actions.GET_SPORT_PREDICTIONS_PICKS, getSportPredictionsPicks),
    takeLatest(Actions.GET_PARLAYS, getParlays),
    takeLatest(Actions.GET_SPORT_PARLAYS, getSportParlays),
    takeLatest(Actions.GET_PREDICTION_PARLAYS, getPredictionParlays),
    takeLatest(Actions.GET_INDIVIDUAL_NEXT_GAMES, getIndividualNextGames),
    takeLatest(Actions.GET_PREDICTION, getPrediction),
    takeLatest(Actions.GET_STATES, getStates),
    takeLatest(Actions.GET_PROMO_CODES, getPromoCodes),
    takeLatest(Actions.GET_STATE_PROMO_CODES, getStatePromoCodes),
    takeLatest(Actions.GET_ACTIVE_PROMO_CODE, getActivePromoCode),
    takeLatest(Actions.GET_SIGNUP_LINK, getSignupLink),
    takeLatest(Actions.GET_HOME_NEWS, getHomeNews),
    takeLatest(Actions.GET_FEATURED_NEWS, getFeaturedNews),
    takeLatest(Actions.GET_RELATED_NEWS, getRelatedNews),
    takeLatest(Actions.GET_SPORT_NEWS, getSportNews),
    takeLatest(Actions.GET_NEWS_ARTICLE, getNewsArticle),
    takeLatest(Actions.GET_STORYDISKS, getStoryDisks),
    takeLatest(Actions.GET_NAVIGATION_TOP, getNavigationTop),
    takeLatest(Actions.GET_NAVIGATION_HAMBURGER, getNavigationHamburger),
    takeLatest(Actions.GET_CONTENT, getContent),
    takeLatest(Actions.GET_FEATURED, getFeatured),
    takeLatest(Actions.GET_PROMO_CODES_SIDEBAR, getPromoCodesSidebar),
    takeLatest(Actions.GET_HYPOMETER, getHypometer),
    takeLatest(Actions.GET_PREDICTIONS_SELECT, getPredictionsSelect),
    takeLatest(Actions.GET_TERMS, getTerms),
    takeLatest(Actions.GET_EVENTS, getEvents),
    takeLatest(Actions.GET_TEAM_GUIDES, getTeamGuides),
    takeLatest(Actions.GET_PREDICTIONS_ENHANCED, getPredictionsEnhanced),
    takeLatest(Actions.GET_GROUPED_PROMO_CODES, getGroupedPromoCodes),
    takeLatest(Actions.GET_PICK_GRID, getPickGrid),
    // END OF TAKE_LATEST
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
  ]);
}

export default rootSaga;
