
export const Project = {
  debug: false,
  api: "https://web.ecs.uat.pickswise.rp-cloudinfra.com/wp-json/pw/v1/",
  apiAuth: "",
  baseUrl: "",
  flagsmith: "4BYJ2xzENGPLzBGJyowAF7",
  flagsmithAPI: "https://flagsmith.pickswise.com/api/v1/",
  imageProxy: "https://images.pickswise.com/",
  logs: {
    EVENTS: true,
    DATA: false,
    STORE: false,
    DISPATCHER: false,
    STORAGE: false,
    SERVER: false,
    API: false,
    PUSH_NOTIFICATIONS: false,
  },
  codepush: {
    android: {
      production: '4lSH8dXD_SlojQdj-OJBuQ9bqjVrCHV1quzbY',
      staging: 'z8OyM_CSfzewsL3u87geEysX5V7xhi5El_c_-',
    },
    ios: {
      production: '0kvqt9tyjsMka6fUYr0kX0Ru0RUuNTWLLbYbJ',
      staging: '4eGXDIGb6JiH5p0749Lytn3nd9yFLL6IV_EfN',
    },
  },
};

export type ProjectType = typeof Project;

export function setProjectValue<K extends keyof ProjectType>(key:K, v:ProjectType[K]) {
  Project[key] = v
}
