export const initTrustArcListener = () => {
    // provided by TrustArc
    let __i__ =
        // @ts-ignore
        self.postMessage &&
        setInterval(function () {
            if (self.PrivacyManagerAPI && __i__) {
                const apiObject = {
                    PrivacyManagerAPI: {
                        action: 'getConsentDecision',
                        timestamp: new Date().getTime(),
                        self: self.location.host,
                    },
                }
                // @ts-ignore
                self.top.postMessage(JSON.stringify(apiObject))
                // @ts-ignore
                __i__ = clearInterval(__i__)
            }
        }, 50)

    self.addEventListener('message', (e) => {
        // sonarqube vulnerability fix below
        if (e.origin !== window.location.origin) return

        try {
            if (typeof e.data === 'string') {
                const data = JSON.parse(e.data)
                if (
                    data &&
                    data.PrivacyManagerAPI &&
                    data.PrivacyManagerAPI.capabilities &&
                    data.PrivacyManagerAPI.action == 'getConsentDecision'
                ) {
                    // @ts-ignore
                    const { consentDecision } = self.PrivacyManagerAPI.callApi(
                        'getConsentDecision',
                        self.location.host
                    )
                    // @ts-ignore
                    if (window && window?.analytics) {
                        if (consentDecision == 1) window.location.reload()
                    }
                }
            }
        } catch (err) {
            // not a CMA notification so ignore
            console.log(err)
        }
    })
}

export const checkCookieConsentLevel = (cookies: { [p: string]: string }) => {
    const { notice_preferences } = cookies || {}
    // consent implied, if no value set default to allowed
    const consentLevel = notice_preferences
        ? notice_preferences.replace(':', '')
        : 3
    return consentLevel > 0
}
