import { useDispatch, useSelector } from "react-redux";
import { AppActions, Callbacks } from "../app-actions";
import { AppState, RequestTypes, TSport, TSportSlug } from "../state-type";
import { useCallback, useMemo } from "react";

type UseSports = {
  inSeasonSports: {
    data: AppState['sports']
    ids: string
  };
  sports: AppState["sports"];
  sportsLoading: AppState["sportsLoading"];
  sportsError: AppState["sportsError"];
  getSports: (data: RequestTypes["getSports"], callbacks?: Callbacks) => void;
  getSport:  (sport:TSportSlug)=> TSport|undefined
};

export default function useSports(): UseSports {
  const { sports, sportsLoading, sportsError } = useSelector(
    (state: AppState) => ({
      sports: state.sports,
      sportsLoading: state.sportsLoading,
      sportsError: state.sportsError,
    })
  );
  const dispatch = useDispatch();
  const getSports = useCallback(
    (data: RequestTypes["getSports"], callbacks?: Callbacks) => {
      return dispatch(AppActions.getSports(data, callbacks));
    },
    [dispatch]
  );
  const getSport = useCallback((sport:TSportSlug)=>{
    return sports?.find((sportDetail)=>sportDetail.slug === sport)
  },[sports])
  const inSeasonSports = useMemo(() => {
    const data = sports?.filter(({ inSeason }) => inSeason)
    return {
      data,
      ids: data?.map(({ slug }) => slug).join(',') || "",
    }
  }, [sports]);
  return {
    inSeasonSports,
    sports,
    sportsLoading,
    sportsError,
    getSports,
    getSport,
  };
}
