import { FC } from 'react'
import Head from 'next/head'
import { getHeadContent, getJsonLdScripts } from './utils'
import { useRouter } from 'next/router'
import useContent from '@ssgat/common/providers/useContent'
import { JSONLdType } from 'project/nextPromiseAction'

type MetaContainerType = {
    pageJsonLd?: JSONLdType
    appJsonLd?: JSONLdType
}

export const MetaContainer: FC<MetaContainerType> = ({
    appJsonLd,
    pageJsonLd,
}) => {
    const { asPath } = useRouter()
    const { content } = useContent()
    const [pageContent] = content?.[asPath] || []
    if (!pageContent?.seoMeta) return null
    return (
        <Head>
            {getHeadContent(pageContent.seoMeta, asPath)}
            {appJsonLd && getJsonLdScripts(appJsonLd)}
            {pageJsonLd && getJsonLdScripts(pageJsonLd)}
        </Head>
    )
}

export default MetaContainer
