import { addClass, useIsMinWidth } from '@ssgat/react-components'
import React from 'react'
import Ad from './Ad'
import { AdContainerProps } from './AdContainer.types'
import { AD_SLOTS } from './AdContainer.consts'
import styles from './AdContainer.module.scss'

export const AdContainer: React.FC<AdContainerProps> = ({
    enabled = false,
}) => {
    const showLargerBanner = useIsMinWidth('large')
    if (!enabled) return null
    return (
        <div className={addClass(styles.container)}>
            <Ad
                {...(showLargerBanner
                    ? AD_SLOTS.banner
                    : AD_SLOTS.banner_mobile)}
                className={addClass(styles.top)}
            />
            <Ad {...AD_SLOTS.skyscraper_1} className={addClass(styles.left)} />
            <Ad {...AD_SLOTS.skyscraper_2} className={addClass(styles.right)} />
        </div>
    )
}
