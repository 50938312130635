import Analytics, { AnalyticsInstance } from 'analytics'
import segmentPlugin from '@analytics/segment'
import getConfig from 'next/config'
import { registerBraze } from './braze'

const devOnlyPlugins = [
    {
        enabled: true,
        // simple logger used only in dev
        name: 'logger',
        // @ts-ignore
        page: ({ payload }) => {
            console.log('page', payload)
        },
        // @ts-ignore
        track: ({ payload }) => {
            console.log('track', payload)
        },
        // @ts-ignore
        identify: ({ payload }) => {
            console.log('identify', payload)
        },
    },
]

export function analyticsInstance(enabled: boolean): AnalyticsInstance {
    const allEnvPlugins = []

    const {
        publicRuntimeConfig: { SEGMENT_KEY, DEVELOPMENT },
    } = getConfig()

    if (SEGMENT_KEY) {
        allEnvPlugins.push(
            segmentPlugin({
                writeKey: SEGMENT_KEY,
                enabled: enabled,
            })
        )
    } else {
        console.warn('Segment key missing: Analytics disabled')
    }

    const analytics = Analytics({
        app: 'pickswise-frontend',
        debug: DEVELOPMENT,
        plugins: [...(DEVELOPMENT ? devOnlyPlugins : []), ...allEnvPlugins],
    })

    analytics.ready(() => {
        registerBraze()
    })

    return analytics
}

export interface WithAnalyticsCallbackReturn {
    page_name: string
    site_section: string
    site_section_1?: string
    category?: string
    article_published_date?: string
    article_modified_date?: string
    article_author?: string
    article_headline?: string
    status?: string
    sport?: string
    match?: string
    match_date?: string
}

export const getAnalytics = (
    data: Partial<WithAnalyticsCallbackReturn>
): WithAnalyticsCallbackReturn => {
    return {
        ...data,
        // in the event no page has a name return these default props
        page_name: data.page_name || 'Generic',
        // if no site section just go with this generic one.
        site_section: data.site_section || 'Misc',
    }
}

import {
    StartedASessionProps,
    TrackEventMap,
} from '@ssgat/react-components/build/types'

export const startSession = (
    trackEvent: <K extends keyof TrackEventMap>(
        eventName: K,
        props: StartedASessionProps
    ) => Promise<any>,
    stateCode: string,
    countryCode: string
): void => {
    const SESSION_OFFSET = 1000 * 60 * 30
    const LAST_VISIT_TIMESTAMP = 'pw_visit_timestamp'

    const recentVisit = localStorage.getItem(LAST_VISIT_TIMESTAMP) ?? '0'
    const currentTime = new Date().getTime()

    if (currentTime > parseInt(recentVisit) + SESSION_OFFSET) {
        trackEvent('Started a Session', {
            state: stateCode?.toLowerCase() || '',
            country: countryCode.toLowerCase() || '',
        })
    }
    localStorage.setItem(LAST_VISIT_TIMESTAMP, currentTime.toString())
}
