import { CSSProperties, FC } from 'react'
import { SidebarDefault, SidebarTwitter } from 'components/Sidebar'
import { FeaturedProps, SectionProps, SideBarProps } from './Layout.types'
import styles from './Layout.module.scss'
import classNames from 'classnames'
import usePromoCodesSidebar from '@ssgat/common/providers/usePromoCodesSidebar'
import { addClass } from '@ssgat/react-components'
export const Section: FC<SectionProps> = ({
    children,
    mobileOrder,
    toMobileEdge,
    showDivider = true,
    className,
}) => {
    const style = {
        '--mobile-order': mobileOrder || 0,
    } as CSSProperties

    return (
        <section
            data-testid="Section"
            className={classNames(
                styles.section,
                {
                    [styles.toMobileEdge]: toMobileEdge,
                    [styles.divider]: showDivider,
                },
                className
            )}
            style={style}
        >
            {children}
        </section>
    )
}

export const Main: FC = ({ children }) => {
    return (
        <main data-testid="Main" className={styles.main}>
            {children}
        </main>
    )
}

export const Featured: FC<FeaturedProps> = ({ children, toMobileEdge }) => {
    return (
        <div
            data-testid="Featured"
            className={addClass(
                styles.featured,
                toMobileEdge ? styles.toMobileEdge : ''
            )}
        >
            {children}
        </div>
    )
}

export const Sidebar: FC<SideBarProps> = ({ sidebarType, HeaderComponent }) => {
    let sidebarContent
    const { promoCodesSidebar } = usePromoCodesSidebar()
    if (sidebarType === 'DEFAULT') {
        if (!promoCodesSidebar) return null
        sidebarContent = (
            <SidebarDefault {...{ promoCodesSidebar, HeaderComponent }} />
        )
    } else if (sidebarType === 'TWITTER') {
        sidebarContent = <SidebarTwitter />
    } else if (sidebarType === 'NONE') {
        return null
    }
    return (
        <div data-testid="Sidebar" className={styles.sidebar}>
            {sidebarContent}
        </div>
    )
}
