import { MenuItemType, isArray, shuffleArray } from '@ssgat/react-components'
import { ActiveNavType, TertiaryNavType } from './Navigation.types'

export const matchUrl = (path: string, url: string): boolean => path === url

export const findNavItem = (
    items: MenuItemType[],
    path: string
    // @ts-ignore
): MenuItemType => items.find((item) => matchUrl(path, item.url))

export const getActiveNav = (
    path: string,
    items?: MenuItemType[]
): ActiveNavType => {
    // @ts-ignore
    if (!items || !isArray(items)) return //isArray([]) === false

    const activeParent = items.find(
        (item) => item.url === path || item.url === `/${path.split('/')[1]}/`
    )

    const allSubNavItems = items
        .filter((item) => isArray(item.subItems))
        .map((filteredItems) => filteredItems.subItems || [])
        .reduce((acc, curr) => acc.concat(curr), [])

    return {
        items,
        activeNavId: activeParent?.id,
        activeSubNavId: findNavItem(allSubNavItems, path)?.id,
        dropItems: items.map((item) => ({ ...item, subItems: [] })),
        subItems: activeParent?.subItems || [],
    }
}
// @ts-ignore
export const shuffleBookmakers = (storyDisks) => {
    //can we guarantee that this works? see tests 🤔
    const orderedKeys = shuffleArray(Object.keys(storyDisks.bookmakers), 5)
    const newOrder = {}
    // @ts-ignore
    orderedKeys.forEach((item: string) => {
        // @ts-ignore
        newOrder[item] = storyDisks.bookmakers[item]
    })

    return {
        ...storyDisks,
        bookmakers: newOrder,
    }
}

export const getTertiaryNav = (
    items: MenuItemType[],
    root: string,
    path: string
): TertiaryNavType | null => {
    if (!items?.length) return null
    let tertiaryMenuItems: MenuItemType[] = []
    items.forEach((item) => {
        if (!item.subItems) return
        item.subItems.forEach((subItem) => {
            if (!subItem.subItems) return
            if (subItem.url === root) tertiaryMenuItems = subItem.subItems
        })
    })
    return {
        items: tertiaryMenuItems,
        activeNavId: findNavItem(tertiaryMenuItems, path)?.id,
    }
}
