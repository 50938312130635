import { FC } from 'react'
import styles from './Layout.module.scss'
import classNames from 'classnames'

export type GridType = {
    withSidebar?: boolean
}

const Grid: FC<GridType> = ({ children, withSidebar = false }) => {
    return (
        <div
            className={classNames([
                styles.grid,
                withSidebar && styles.withSidebar,
            ])}
        >
            {children}
        </div>
    )
}

export default Grid
