import { FC } from 'react'
import { CardGrid } from '@ssgat/react-components'
import { Timeline } from 'react-twitter-widgets'
import { Section } from '../Layout/Layout'
import styles from './Sidebar.module.scss'
import Strings from 'project/localization'
export const SidebarTwitter: FC = () => (
    <Section showDivider={false} mobileOrder={10} className={styles.twitter}>
        <CardGrid
            title={Strings.twitter}
            singleColumn={true}
            mobileSlider={false}
        >
            <Timeline
                dataSource={{
                    sourceType: 'profile',
                    screenName: 'pickswise',
                }}
                options={{ height: '1231' }}
            />
        </CardGrid>
    </Section>
)
