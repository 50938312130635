import { FC, useEffect, useState } from 'react'
import { NavigationProps } from './Navigation.types'
import {
    ImageLoadingContextProvider,
    Logo,
    MainMenu,
    MenuIconLink,
    MenuIconLinkProps,
    StoryDisks,
    SubMenu,
    TopMenu,
    lookupIcon,
} from '@ssgat/react-components'
export const TYPENAME_ARRAY = 'Array'
export const TYPENAME_OBJECT = 'Object'

// references the public static dir
export const LOGO_MOBILE =
    'https://www.pickswise.com/wp-content/offload/uploads/2021/08/13062008/Pickswise-stacked-2color-logo.png'
export const LOGO_DESKTOP =
    'https://www.pickswise.com/wp-content/offload/uploads/2021/07/23074145/Pickswise-2color-logo.png'

import { getActiveNav, shuffleBookmakers } from './utils'
import styles from './Navigation.module.scss'
import useSports from '@ssgat/common/providers/useSports'
import useNavigationTop from '@ssgat/common/providers/useNavigationTop'
import useNavigationHamburger from '@ssgat/common/providers/useNavigationHamburger'
import useStoryDisks from '@ssgat/common/providers/useStoryDisks'
import Strings from 'project/localization'
const Navigation: FC<NavigationProps> = ({ path }) => {
    const { storyDisks } = useStoryDisks()
    const { sports } = useSports()
    const { navigationTop } = useNavigationTop()
    const { navigationHamburger } = useNavigationHamburger()
    const [newBookmakerOrder, setNewBookmakerOrder] = useState(storyDisks)

    const { items, activeNavId, subItems, activeSubNavId, dropItems } =
        getActiveNav(path.endsWith('/') ? path : `${path}/`, navigationTop)

    useEffect(() => {
        setNewBookmakerOrder(shuffleBookmakers(storyDisks))
    }, [storyDisks, path])

    const picksMenuItems = sports
        ?.filter(({ href, pickCount }) => href && pickCount)
        .map((sport) => {
            const { name, pickCount, id, childPages } = sport
            return {
                title: Strings.formatString(
                    Strings.sportPicksCount,
                    name,
                    `${pickCount}`
                ),
                url: childPages?.picks,
                // @ts-ignore
                icon: lookupIcon(name),
                id,
            }
        })

    const logoProps = {
        siteLogoMobile: {
            src: LOGO_MOBILE,
        },
        siteLogoDesktop: {
            src: LOGO_DESKTOP,
        },
        siteHome: '/',
        siteLogoAlt: Strings.pickswiseLogo,
    }

    const bonusesLinkProps: MenuIconLinkProps = {
        href: '/sportsbooks/best',
        icon: 'icon-filled-sports-book',
        // @ts-ignore todo: I don't think MenuNameType is localisation friendly
        title: Strings.bonuses,
        showOn: {
            desktop: true,
            tablet: true,
            mobile: true,
        },
    }

    const analysisLinkProps: MenuIconLinkProps = {
        href: '/news',
        icon: 'icon-filled-news',
        // @ts-ignore todo: I don't think MenuNameType is localisation friendly
        title: Strings.analysis,
        showOn: { desktop: true, tablet: true },
    }

    const picksMenuProps: MenuIconLinkProps = {
        title: Strings.picks,
        icon: 'icon-filled-picks',
        // @ts-ignore
        items: picksMenuItems,
        activeItemId: 3,
        // @ts-ignore todo: I don't think MenuNameType is localisation friendly
        menuName: Strings.picks,
        showOn: {
            desktop: true,
            tablet: true,
            mobile: true,
        },
    }

    const moreMenuProps: MenuIconLinkProps = {
        icon: 'icon-hamburger',
        showOn: { desktop: true, tablet: true, mobile: true },
        isSideMenu: true,
        items: navigationHamburger,
        activeItemId: 4,
        // @ts-ignore todo: I don't think MenuNameType is localisation friendly
        menuName: Strings.more,
    }

    return (
        <div className={styles.navigation}>
            <ImageLoadingContextProvider loading="eager">
                <MainMenu
                    logo={<Logo {...logoProps} />}
                    iconLinks={[
                        <MenuIconLink
                            key="AnalysisLink"
                            {...analysisLinkProps}
                        />,
                        <MenuIconLink
                            key="BonusesLink"
                            {...bonusesLinkProps}
                        />,
                        <MenuIconLink key="PicksMenu" {...picksMenuProps} />,
                        <MenuIconLink key="MoreMenu" {...moreMenuProps} />,
                    ]}
                    topMenuComponent={
                        <TopMenu
                            items={items}
                            maxVisibleItems={6}
                            activeId={activeNavId}
                        />
                    }
                    subMenuComponent={
                        <SubMenu
                            items={subItems}
                            dropDownItems={dropItems}
                            currentSportId={activeNavId}
                            currentPage={activeSubNavId}
                        />
                    }
                />
                {storyDisks && newBookmakerOrder && (
                    <StoryDisks {...newBookmakerOrder} />
                )}
            </ImageLoadingContextProvider>
        </div>
    )
}

export default Navigation
