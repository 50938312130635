import { useDispatch, useSelector } from "react-redux";
import { AppActions, Callbacks } from "../app-actions";
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from "react";

type UseStoryDisks = {
  storyDisks: AppState["storyDisks"];
  storyDisksLoading: AppState["storyDisksLoading"];
  storyDisksError: AppState["storyDisksError"];
  getStoryDisks: (
    data: RequestTypes["getStoryDisks"],
    callbacks?: Callbacks
  ) => void;
};

export default function useStoryDisks(): UseStoryDisks {
  const { storyDisks, storyDisksLoading, storyDisksError } = useSelector(
    (state: AppState) => ({
      storyDisks: state.storyDisks,
      storyDisksLoading: state.storyDisksLoading,
      storyDisksError: state.storyDisksError,
    })
  );
  const dispatch = useDispatch();
  const getStoryDisks = useCallback((data: RequestTypes['getStoryDisks'], callbacks?: Callbacks) => {
    return dispatch(AppActions.getStoryDisks(data, callbacks))
  }, [dispatch])

  return {
    storyDisks,
    storyDisksLoading,
    storyDisksError,
    getStoryDisks,
  };
}

export function useStorybookOffers(): any {
  const { storyDiskViewedOffers } = useSelector((state: AppState) => ({
    storyDiskViewedOffers: state.storyDiskViewedOffers,
  }));
  const dispatch = useDispatch();

  const updateStoryDiskViewedOffers = useCallback(
    (
      data: RequestTypes["updateStoryDiskViewedOffers"],
      callbacks?: Callbacks
    ) => {
      return dispatch(AppActions.updateStoryDiskViewedOffers(data, callbacks));
    },
    [dispatch]
  );

  const bulkUpdateStoryDiskViewedOffers = useCallback(
    (
      data: RequestTypes["bulkUpdateStoryDiskViewedOffers"],
      callbacks?: Callbacks
    ) => {
      return dispatch(
        AppActions.bulkUpdateStoryDiskViewedOffers(data, callbacks)
      );
    },
    [dispatch]
  );

  return {
    storyDiskViewedOffers,
    updateStoryDiskViewedOffers,
    bulkUpdateStoryDiskViewedOffers,
  };
}
