import { FC, useEffect, useState } from 'react'
import {
    CardGrid,
    ImageLoadingContextProvider,
    Offer,
    OfferType,
    isArray,
    shuffleArray,
} from '@ssgat/react-components'
import { Section } from '../Layout/Layout'
import { SideBarOfferProps } from './Sidebar.types'

export const SidebarDefault: FC<SideBarOfferProps> = ({
    promoCodesSidebar,
    HeaderComponent,
}) => {
    const { href, promoCodes = [], title } = promoCodesSidebar || {}
    const [newPromoCodes, setNewPromoCodes] = useState<OfferType[]>(promoCodes)

    useEffect(() => {
        setNewPromoCodes(shuffleArray([...promoCodes], 5) as OfferType[])
    }, [promoCodes])

    return (
        <>
            {HeaderComponent && <HeaderComponent />}
            {isArray(promoCodes) && (
                <Section showDivider={false} mobileOrder={20}>
                    <CardGrid
                        title={title}
                        href={href}
                        singleColumn={true}
                        mobileSlider={false}
                    >
                        {newPromoCodes.map((promocode, idx) => (
                            <ImageLoadingContextProvider
                                key={promocode.id}
                                loading="eager"
                            >
                                <Offer
                                    position={idx + 1}
                                    componentLocation="Sidebar"
                                    {...promocode}
                                />
                            </ImageLoadingContextProvider>
                        ))}
                    </CardGrid>
                </Section>
            )}
        </>
    )
}
