export const AD_SLOTS = {
    banner: {
        id: 'banner',
        size: [728, 90],
        slot: '/5614/Pickswise/728x90',
    },
    banner_mobile: {
        id: 'banner_mobile',
        size: [320, 50],
        slot: '/5614/Pickswise/320x50',
    },
    skyscraper_1: {
        id: 'skyscraper_1',
        slot: '/5614/Pickswise/120x600',
        size: [120, 600],
    },
    skyscraper_2: {
        id: 'skyscraper_2',
        slot: '/5614/Pickswise/120x600_2',
        size: [120, 601],
    },
}
