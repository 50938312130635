
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UseContent = {
  content: AppState['content'],
  contentLoading: AppState['contentLoading'],
  contentError: AppState['contentError'],
  getContent: (data:RequestTypes['getContent'], callbacks?:Callbacks)=>void,
}

export default function useContent():UseContent {
  const {
    content, contentLoading, contentError } = useSelector((state:AppState)=>({
    content: state.content,
    contentLoading: state.contentLoading,
    contentError: state.contentError,
  }));
  const dispatch = useDispatch();
  const getContent = useCallback((data:RequestTypes['getContent'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getContent(data, callbacks))
  },[dispatch])
  return {
    content,
    contentLoading,
    contentError,
    getContent,
  }
}
