import produce, { enableES5 } from "immer";
import { Actions } from "./app-actions";
import { AppState, RequestTypes, SportNewsItem } from "./state-type";
import "./utils";
import { getApi } from "./api/api";
// eslint-disable-next-line no-unused-vars
import { itemError, itemLoaded, itemLoading } from "./utils/reducer-helpers";
enableES5(); // required for react native hermes

const defaultReducer = produce((state: AppState, action): AppState | void => {
  if (typeof window === "undefined") {
    getApi().log("SERVER", action.type, action.data);
    if (action.type.includes("ERROR")) {
      getApi().log("SERVER", action);
    }
  } else {
    getApi().log("DISPATCHER", action.type);
  }

  // TODO consider having a default case for error handling
  //eslint-disable-next-line default-case
  switch (action.type) {
    case Actions.LOGIN_LOADED:
    case Actions.UPDATE_USER_LOADED:
    case Actions.CONFIRM_EMAIL_LOADED:
    case Actions.REGISTER_LOADED:
      itemLoaded(state, "profile", action);
      break;
    case Actions.LOGIN_ERROR:
    case Actions.CONFIRM_EMAIL_ERROR:
    case Actions.REGISTER_ERROR:
    case Actions.UPDATE_USER_ERROR:
      itemError(state, "profile", action);
      break;
    case Actions.REGISTER:
    case Actions.UPDATE_USER:
    case Actions.CONFIRM_EMAIL:
    case Actions.LOGIN:
      itemLoading(state, "profile");
      break;
    case Actions.CLEAR_USER:
      if (state.profile?.id) {
        getApi().push?.unsubscribe(`${state.profile.id}`);
      }
      state.profile = undefined;
      break;
    case Actions.STARTUP_LOADED:
      Object.keys(action.data).map((k) => {
        state[k] = action.data[k];
      });
      break;
    case Actions.SET_ACTIVE_SCREEN:
      itemLoaded(state, "activeScreen", action, true);
      break;

      // GET PICKS

    case Actions.GET_PICKS:
      return itemLoading(state, "picks");

    case Actions.GET_PICKS_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, "picks", action);

    case Actions.GET_PICKS_ERROR:
      return itemError(state, "picks", action);

      // GET_BEST_BETS_BY_SPORT

    case Actions.GET_BEST_BETS_BY_SPORT:
      const bestBetsKey = action.data.pageSlug || action.data.sports
      if (!state.bestBetsBySport) {
        state.bestBetsBySport = {};
      }

      if (!state.bestBetsBySport[bestBetsKey]) {
        state.bestBetsBySport[bestBetsKey] = {
          data: [],
          isLoading: true,
        };
      } else {
        state.bestBetsBySport[bestBetsKey].isLoading = true;
        state.bestBetsBySport[bestBetsKey].error = undefined;
      }
      break;

    case Actions.GET_BEST_BETS_BY_SPORT_LOADED:
      state.bestBetsBySport = state.bestBetsBySport || {};
      const bestBetsLoadedKey = action.originalAction.data.pageSlug || action.originalAction.data.sports
      state.bestBetsBySport[bestBetsLoadedKey].isLoading =
        false;
      state.bestBetsBySport[bestBetsLoadedKey].data =
        action.data.data;
      break;

    case Actions.GET_BEST_BETS_BY_SPORT_ERROR:
      const bestBetsErrorKey = action.originalAction.data.pageSlug || action.originalAction.data.sports
      state.bestBetsBySport = state.bestBetsBySport || {};
      state.bestBetsBySport[bestBetsErrorKey].error =
        action.error;
      state.bestBetsBySport[bestBetsErrorKey].isLoading =
        false;
      state.bestBetsBySport[bestBetsErrorKey].isSaving = false;
      break;

    case Actions.GET_SPORTS:
      return itemLoading(state, "sports");
    case Actions.GET_SPORTS_LOADED:
      return itemLoaded(state, "sports", action);
    case Actions.GET_SPORTS_ERROR:
      return itemError(state, "sports", action);

    case Actions.UPDATE_SPORT:
      const actionData: RequestTypes["updateSport"] = action.data;
      state.sport = actionData;
      if (action.onSuccess) {
        setTimeout(() => {
          action.onSuccess();
        }, 0);
      }
      return state;

    case Actions.UPDATE_LAST_USED_SPORT:
      const data: RequestTypes["updateLastUsedSport"] = action.data;
      state.lastUsedSport = data;
      if (action.onSuccess) {
        setTimeout(() => {
          action.onSuccess();
        }, 0);
      }
      return state;

    case Actions.GET_SPORT_FUTURES:
      return itemLoading(state, "sportFutures");
    case Actions.GET_SPORT_FUTURES_LOADED:
      action.index = action.originalAction.data.sports || "all";
      return itemLoaded(state, "sportFutures", action);
    case Actions.GET_SPORT_FUTURES_ERROR:
      return itemError(state, "sportFutures", action);

    case Actions.GET_NEXT_UP:
      return itemLoading(state, "nextUp");
    case Actions.GET_NEXT_UP_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"

      return itemLoaded(state, "nextUp", action);
    case Actions.GET_NEXT_UP_ERROR:
      return itemError(state, "nextUp", action);

    case Actions.GET_NEXT_UP_BY_SPORT:
      return itemLoading(state, "nextUpBySport");
    case Actions.GET_NEXT_UP_BY_SPORT_LOADED:
      action.index = action.originalAction.data.sports || "all";
      return itemLoaded(state, "nextUpBySport", action);
    case Actions.GET_NEXT_UP_BY_SPORT_ERROR:
      return itemError(state, "nextUpBySport", action);

    case Actions.GET_PREDICTIONS:
      return itemLoading(state, "predictions");
    case Actions.GET_PREDICTIONS_LOADED:
      return itemLoaded(state, "predictions", action);
    case Actions.GET_PREDICTIONS_ERROR:
      return itemError(state, "predictions", action);

    case Actions.GET_STATES:
      return itemLoading(state, "states");
    case Actions.GET_STATES_LOADED:
      return itemLoaded(state, "states", action);
    case Actions.GET_STATES_ERROR:
      return itemError(state, "states", action);

    case Actions.GET_PROMO_CODES:
      return itemLoading(state, "promoCodes");
    case Actions.GET_PROMO_CODES_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, "promoCodes", action);
    case Actions.GET_PROMO_CODES_ERROR:
      return itemError(state, "promoCodes", action);

    case Actions.GET_STATE_PROMO_CODES:
      return itemLoading(state, "statePromoCodes");
    case Actions.GET_STATE_PROMO_CODES_LOADED:
      action.index = action.originalAction.data.region || "all";
      return itemLoaded(state, "statePromoCodes", action);
    case Actions.GET_STATE_PROMO_CODES_ERROR:
      return itemError(state, "statePromoCodes", action);

    case Actions.GET_ACTIVE_PROMO_CODE:
      return itemLoading(state, "activePromoCode");
    case Actions.GET_ACTIVE_PROMO_CODE_LOADED:
      return itemLoaded(state, "activePromoCode", action);
    case Actions.GET_ACTIVE_PROMO_CODE_ERROR:
      return itemError(state, "activePromoCode", action);

    case Actions.GET_SPORT_PREDICTIONS:
      return itemLoading(state, "sportPredictions");
    case Actions.GET_SPORT_PREDICTIONS_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.sports || "all"
      return itemLoaded(state, "sportPredictions", action);
    case Actions.GET_SPORT_PREDICTIONS_ERROR:
      return itemError(state, "sportPredictions", action);

    case Actions.GET_SPORT_PREDICTIONS_PICKS:
      return itemLoading(state, "sportPredictionsPicks");
    case Actions.GET_SPORT_PREDICTIONS_PICKS_LOADED:
      action.index = action.originalAction.data.pageSlug ||action.originalAction.data.sports || "all";
      return itemLoaded(state, "sportPredictionsPicks", action);
    case Actions.GET_SPORT_PREDICTIONS_PICKS_ERROR:
      return itemError(state, "sportPredictionsPicks", action);

    case Actions.GET_PARLAYS:
      return itemLoading(state, "parlays");
    case Actions.GET_PARLAYS_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, "parlays", action);
    case Actions.GET_PARLAYS_ERROR:
      return itemError(state, "parlays", action);

    case Actions.GET_SPORT_PARLAYS:
      return itemLoading(state, "sportParlays");
    case Actions.GET_SPORT_PARLAYS_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.sports || "all";
      return itemLoaded(state, "sportParlays", action);
    case Actions.GET_SPORT_PARLAYS_ERROR:
      return itemError(state, "sportParlays", action);

    case Actions.GET_PREDICTION_PARLAYS:
      return itemLoading(state, "predictionParlays");
    case Actions.GET_PREDICTION_PARLAYS_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.id;
      return itemLoaded(state, "predictionParlays", action);
    case Actions.GET_PREDICTION_PARLAYS_ERROR:
      return itemError(state, "predictionParlays", action);

    case Actions.GET_INDIVIDUAL_NEXT_GAMES:
      return itemLoading(state, "individualNextGames");
    case Actions.GET_INDIVIDUAL_NEXT_GAMES_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.exclude;
      return itemLoaded(state, "individualNextGames", action);
    case Actions.GET_INDIVIDUAL_NEXT_GAMES_ERROR:
      return itemError(state, "individualNextGames", action);

    case Actions.GET_PREDICTION:
      return itemLoading(state, "prediction");
    case Actions.GET_PREDICTION_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.id;
      return itemLoaded(state, "prediction", action);
    case Actions.GET_PREDICTION_ERROR:
      return itemError(state, "prediction", action);
    case Actions.SET_FLAGSMITH:
      state.flagsmith = action.data;
      return state;
    case Actions.SET_LINK_HANDLING_DATA:
      state.linkHandlingData = action.data;
      return state;
    case Actions.GET_SIGNUP_LINK:
      return itemLoading(state, "signupLink");
    case Actions.GET_SIGNUP_LINK_LOADED:
      action.index = action.originalAction.data.id;
      return itemLoaded(state, "signupLink", action);
    case Actions.GET_SIGNUP_LINK_ERROR:
      return itemError(state, "signupLink", action);

    case Actions.GET_HOME_NEWS:
      return itemLoading(state, "homeNews");
    case Actions.GET_HOME_NEWS_LOADED:
      return itemLoaded(state, "homeNews", action);
    case Actions.GET_HOME_NEWS_ERROR:
      return itemError(state, "homeNews", action);

    case Actions.GET_FEATURED_NEWS:
      return itemLoading(state, "featuredNews");
    case Actions.GET_FEATURED_NEWS_LOADED:
      action.index = action.originalAction.data.sports?.includes(",")
        ? "all"
        : action.originalAction.data.sports || "all";
      action.index = action.originalAction.data.pageSlug || (action.originalAction.data.sports?.includes(",")? "all" :  (action.originalAction.data.sports || "all"))
      return itemLoaded(state, "featuredNews", action);
    case Actions.GET_FEATURED_NEWS_ERROR:
      return itemError(state, "featuredNews", action);

    case Actions.GET_RELATED_NEWS:
      return itemLoading(state, "relatedNews");
    case Actions.GET_RELATED_NEWS_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.id;
      return itemLoaded(state, "relatedNews", action);
    case Actions.GET_RELATED_NEWS_ERROR:
      return itemError(state, "relatedNews", action);

    case Actions.GET_NEWS_ARTICLE:
      return itemLoading(state, "newsArticles");
    case Actions.GET_NEWS_ARTICLE_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.id;
      return itemLoaded(state, "newsArticles", action);
    case Actions.GET_NEWS_ARTICLE_ERROR:
      return itemError(state, "newsArticles", action);

    case Actions.GET_SPORT_NEWS:
      return itemLoading(state, "sportNews");
    case Actions.GET_SPORT_NEWS_LOADED:
      const sportsNewsData: SportNewsItem = action.data.data;
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.sports[0] || "all";
      if (!action.originalAction.data.pageSlug && (sportsNewsData.pagination.currentPage !== 1 && state.sportNews)) {
        // concat data to existing
        action.data = {
          data: {
            ...sportsNewsData,
            articles: state.sportNews[action.index].articles.concat(
              sportsNewsData.articles
            ),
          },
        };
      }
      return itemLoaded(state, "sportNews", action);
    case Actions.GET_SPORT_NEWS_ERROR:
      return itemError(state, "sportNews", action);
    case Actions.SCREEN_ERROR:
      // Clear Items within the reducer to protect against corrupt api data
      state.bestBetsBySport = undefined;
      state.featuredNews = undefined;
      state.nextUpBySport = undefined;
      state.homeNews = undefined;
      state.nextUp = undefined;
      state.parlays = undefined;
      state.picks = undefined;
      state.predictions = undefined;
      state.profile = undefined;
      state.promoCodes = undefined;
      state.sportFutures = undefined;
      state.sportParlays = undefined;
      state.sportPredictions = undefined;
      state.statePromoCodes = undefined;
      state.storyDisks = undefined;
      action.onSuccess && action.onSuccess();
      return state;
    case Actions.GET_STORYDISKS:
      return itemLoading(state, "storyDisks");
    case Actions.GET_STORYDISKS_LOADED:
      return itemLoaded(state, "storyDisks", action);
    case Actions.GET_STORYDISKS_ERROR:
      return itemError(state, "storyDisks", action);
    case Actions.SET_CURRENT_STORYBOOK_OFFER:
      const currentStoryOfferData: RequestTypes["setCurrentStorybookOffer"] =
        action.data;
      state.currentStorybookOffer = currentStoryOfferData;
      if (action.onSuccess) {
        setTimeout(() => {
          action.onSuccess();
        }, 0);
      }
      return state;
    case Actions.UPDATE_STORY_DISK_VIEWED_OFFERS:
      const storyDiskViewedOffer: RequestTypes["updateStoryDiskViewedOffers"] =
        action.data;
      if (
        state.storyDiskViewedOffers &&
        state.storyDiskViewedOffers.length > 0
      ) {
        if (!state.storyDiskViewedOffers.includes(storyDiskViewedOffer)) {
          state.storyDiskViewedOffers = [
            ...state.storyDiskViewedOffers,
            storyDiskViewedOffer,
          ];
        }
      } else state.storyDiskViewedOffers = [storyDiskViewedOffer];
      if (action.onSuccess) {
        setTimeout(() => {
          action.onSuccess();
        }, 0);
      }
      return state;
    case Actions.BULK_UPDATE_STORY_DISK_VIEWED_OFFERS:
      const bulkUpdateStoryDiskViewedOffers: RequestTypes["bulkUpdateStoryDiskViewedOffers"] =
        action.data;
      if (bulkUpdateStoryDiskViewedOffers?.length > -1)
        state.storyDiskViewedOffers = [...bulkUpdateStoryDiskViewedOffers];
      if (action.onSuccess) {
        setTimeout(() => {
          action.onSuccess();
        }, 0);
      }
      return state;
    case Actions.NEXTJS_HYDRATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case Actions.GET_NAVIGATION_TOP:
      return itemLoading(state, 'navigationTop');
    case Actions.GET_NAVIGATION_TOP_LOADED:
      return itemLoaded(state, 'navigationTop', action);
    case Actions.GET_NAVIGATION_TOP_ERROR:
      return itemError(state, 'navigationTop', action);
    case Actions.GET_NAVIGATION_HAMBURGER:
      return itemLoading(state, 'navigationHamburger');
    case Actions.GET_NAVIGATION_HAMBURGER_LOADED:
      return itemLoaded(state, 'navigationHamburger', action);
    case Actions.GET_NAVIGATION_HAMBURGER_ERROR:
      return itemError(state, 'navigationHamburger', action);
    case Actions.GET_CONTENT:
      return itemLoading(state, 'content');
    case Actions.GET_CONTENT_LOADED:
      action.index = action.originalAction.data.pageSlug;
      return itemLoaded(state, 'content', action);
    case Actions.GET_CONTENT_ERROR:
      return itemError(state, 'content', action);
    case Actions.GET_FEATURED:
      return itemLoading(state, 'featured');
    case Actions.GET_FEATURED_LOADED:
      action.index = action.originalAction.data.pageSlug
      return itemLoaded(state, 'featured', action);
    case Actions.GET_FEATURED_ERROR:
      return itemError(state, 'featured', action);
    case Actions.GET_PROMO_CODES_SIDEBAR:
      return itemLoading(state, 'promoCodesSidebar');
    case Actions.GET_PROMO_CODES_SIDEBAR_LOADED:
      return itemLoaded(state, 'promoCodesSidebar', action);
    case Actions.GET_PROMO_CODES_SIDEBAR_ERROR:
      return itemError(state, 'promoCodesSidebar', action);
    case Actions.GET_HYPOMETER:
      return itemLoading(state, 'hypometer');
    case Actions.GET_HYPOMETER_LOADED:
      action.index = action.originalAction.data.pageSlug || action.originalAction.data.sport || "all";
      return itemLoaded(state, 'hypometer', action);
    case Actions.GET_HYPOMETER_ERROR:
      return itemError(state, 'hypometer', action);
    case Actions.GET_PREDICTIONS_SELECT:
      return itemLoading(state, 'predictionsSelect');
    case Actions.GET_PREDICTIONS_SELECT_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, 'predictionsSelect', action);
    case Actions.GET_PREDICTIONS_SELECT_ERROR:
      return itemError(state, 'predictionsSelect', action);
    case Actions.GET_TERMS:
      return itemLoading(state, 'terms');
    case Actions.GET_TERMS_LOADED:
      return itemLoaded(state, 'terms', action);
    case Actions.GET_TERMS_ERROR:
      return itemError(state, 'terms', action);
    case Actions.GET_EVENTS:
      return itemLoading(state, 'events');
    case Actions.GET_EVENTS_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, 'events', action);
    case Actions.GET_EVENTS_ERROR:
      return itemError(state, 'events', action);
    case Actions.GET_TEAM_GUIDES:
      return itemLoading(state, 'teamGuides');
    case Actions.GET_TEAM_GUIDES_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, 'teamGuides', action);
    case Actions.GET_TEAM_GUIDES_ERROR:
      return itemError(state, 'teamGuides', action);
    case Actions.GET_PREDICTIONS_ENHANCED:
      return itemLoading(state, 'predictionsEnhanced');
    case Actions.GET_PREDICTIONS_ENHANCED_LOADED:
      action.index = action.originalAction.data.pageSlug || "all"
      return itemLoaded(state, 'predictionsEnhanced', action);
    case Actions.GET_PREDICTIONS_ENHANCED_ERROR:
      return itemError(state, 'predictionsEnhanced', action);
    case Actions.GET_GROUPED_PROMO_CODES:
      return itemLoading(state, 'groupedPromoCodes');
    case Actions.GET_GROUPED_PROMO_CODES_LOADED:
      action.index = action.originalAction.data.pageSlug || "all";
      return itemLoaded(state, 'groupedPromoCodes', action);
    case Actions.GET_GROUPED_PROMO_CODES_ERROR:
      return itemError(state, 'groupedPromoCodes', action);
    case Actions.GET_PICK_GRID:
      return itemLoading(state, 'pickGrid');
    case Actions.GET_PICK_GRID_LOADED:
      action.index = action.originalAction.data.pageSlug || "all";
      return itemLoaded(state, 'pickGrid', action);
    case Actions.GET_PICK_GRID_ERROR:
      return itemError(state, 'pickGrid', action);
    // END OF REDUCER
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
    // default:
    //   break;
  }
}, {});

export default defaultReducer;
