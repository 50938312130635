import { TMeta } from '@ssgat/common/state-type'
import { DetailedReactHTMLElement, HTMLAttributes, createElement } from 'react'

export const APP_ID_IOS = '1573774954'
export const APP_ARG_IOS = ''

type createElementType = DetailedReactHTMLElement<
    HTMLAttributes<HTMLElement>,
    HTMLElement
>[]

const iOSAppArgument = APP_ARG_IOS ? ` app-argument=${APP_ARG_IOS}` : ''

const link = {
    tag: 'link',
    rel: 'icon',
    type: 'image/png',
}

const icons = [
    {
        ...link,
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/logos/apple-touch-icon.png',
    },
    {
        ...link,
        sizes: '32x32',
        href: '/logos/favicon-32x32.png',
    },
    {
        ...link,
        sizes: '16x16',
        href: '/logos/favicon-16x16.png',
    },
]

const manifest = { tag: 'link', rel: 'manifest', href: '/manifest.json' }

const theme = {
    tag: 'meta',
    name: 'theme-color',
    content: '#041c2c',
    key: 'theme',
}

const facebook = {
    tag: 'meta',
    name: 'facebook-domain-verification',
    content: '4i3i75rg7htpfa3udowfg7yr3r64rj',
    key: 'facebook-domain-verification',
}

const appleStore = {
    tag: 'meta',
    name: 'apple-itunes-app',
    content: `app-id=${APP_ID_IOS}${iOSAppArgument}`,
    key: 'apple-itunes-app',
}

const baseMeta = [...icons, manifest, theme, facebook, appleStore] as TMeta[]

/**
 * Do not convert this to a component
 *
 * https://nextjs.org/docs/api-reference/next/head
 * "title, Meta need to be contained as direct children of the Head element,
 * or wrapped into maximum one level of <React.Fragment> or arrays, otherwise
 * the tags won't be correctly picked up on client-side navigations."
 */

export const getHeadContent = (
    seoMeta: TMeta[],
    pathname: string
): createElementType => {
    const allMeta = seoMeta.concat(baseMeta).filter(({ tag }) => tag)

    allMeta.sort((a, b) => b.tag.localeCompare(a.tag))

    return allMeta.map(({ tag, innerText, ...props }, index) =>
        createElement(tag, { ...props, key: `${pathname}-${index}` }, innerText)
    )
}

export const getJsonLdScripts = (jsonLd: any[]) => {
    return (
        jsonLd.length > 0 &&
        jsonLd.flat().map((item) => (
            <script
                key={item['@type']}
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(item).replace(/(&quot;)/g, '"'),
                }}
            ></script>
        ))
    )
}
