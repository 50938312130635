import { AppState } from '@ssgat/common/state-type'
import { Store } from 'redux'
import { AppActions } from '@ssgat/common/app-actions'
export type JSONLdType = Record<string, string | Record<string, string>>[]
export type AnyServerResponseType = {
    data: any
    message?: string
    jsonLd?: JSONLdType
}
export function nextPromiseAction<K extends keyof typeof AppActions>(
    store: Store<AppState>,
    appAction: K,
    actionData: Parameters<typeof AppActions[K]>[0]
): Promise<{ jsonLd: JSONLdType }> {
    return new Promise((resolve) => {
        store.dispatch(
            // @ts-ignore todo: if Anyone knows how to improve the type of appAction so this passes
            AppActions[appAction](actionData, {
                onSuccess: (data: AnyServerResponseType) => {
                    resolve({ jsonLd: data.jsonLd || [] })
                },
                onError: () => resolve({ jsonLd: [] }),
            })
        )
    })
}

export async function combineNextPromiseActions(
    promises: Promise<{ jsonLd: JSONLdType }>[]
): Promise<JSONLdType> {
    return (
        await Promise.all(promises.map((p) => p.then(({ jsonLd }) => jsonLd)))
    ).flat()
}
