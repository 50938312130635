import { API } from 'project/api'
import 'project/polyfill'
import App, { AppInitialProps, AppProps } from 'next/app'
import Link from 'next/link'
import 'styles/Global.scss'
import LanguageHandler from '@ssgat/common/LanguageHandler'
import { setApi } from '@ssgat/common/api/api'
import {
    AppContextProvider,
    CookieCompliance,
    InternalLinkProvider,
    TranslationContextProvider,
} from '@ssgat/react-components'
import FontLoader from 'components/util/FontLoader'
import Grid from 'components/Layout/Grid'
import {
    combineNextPromiseActions,
    JSONLdType,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { Navigation } from 'components/Navigation'
import { Footer } from 'components/Footer'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import { Sidebar } from 'components/Layout/Layout'
import MetaContainer from 'components/Meta/MetaContainer'
import AnalyticsContainer from 'components/AnalyticsContainer'
import StateTransfer from 'components/util/StateTransfer'
import { parseCookies } from 'nookies'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import NProgress from 'components/util/NProgress'
import { AdContainer } from 'components/Ads/AdContainer'

// this makes sure setApi initializes API to common before getApi is used
setApi(API)

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

//@ts-ignore
class WrappedApp extends App<AppInitialProps> {
    public static getInitialProps = nextReduxWrapper.getInitialAppProps(
        (store) =>
            async ({ ctx }) => {
                let appJsonLd: JSONLdType = []

                const countryCode = `${
                    ctx.req?.headers?.['geo-country'] || 'US'
                }`
                const stateCode = ctx.req?.headers?.['geo-region'] || ''

                try {
                    const locale = API.getLocaleFromRequest(ctx.req),
                        pageSlug = ctx?.query?.pageSlug
                            ? `${ctx?.query?.pageSlug}`
                            : '' || ctx.asPath?.split('?')[0] || '/'
                    let toResolve = []

                    if (!store.getState().content?.[pageSlug]) {
                        toResolve.push(
                            nextPromiseAction<'getContent'>(
                                store,
                                'getContent',
                                {
                                    pageSlug: pageSlug,
                                }
                            )
                        )
                    }

                    if (!store.getState().sports) {
                        await nextPromiseAction<'startup'>(store, 'startup', {
                            locale,
                            extraData: {
                                country: countryCode,
                                region: stateCode,
                            },
                        })
                        toResolve = toResolve.concat([
                            nextPromiseAction<'getSports'>(store, 'getSports', {
                                pageSlug: '/',
                            }),
                            nextPromiseAction<'getNavigationTop'>(
                                store,
                                'getNavigationTop',
                                {
                                    pageSlug: '/',
                                }
                            ),
                            nextPromiseAction<'getNavigationHamburger'>(
                                store,
                                'getNavigationHamburger',
                                {
                                    pageSlug: '/',
                                }
                            ),
                            nextPromiseAction<'getPromoCodesSidebar'>(
                                store,
                                'getPromoCodesSidebar',
                                {}
                            ),
                            nextPromiseAction<'getStoryDisks'>(
                                store,
                                'getStoryDisks',
                                {}
                            ),
                        ])
                    }

                    appJsonLd = await combineNextPromiseActions(toResolve)
                } catch (e) {
                    console.error(e)
                }
                const cookies = parseCookies({ req: ctx.req })

                return {
                    pageProps: {
                        appJsonLd,
                        cookies,
                        countryCode,
                        stateCode,
                    },
                }
            }
    )

    public render() {
        const {
            Component,
            pageProps,
            router: { asPath },
        }: AppPropsWithLayout = this.props
        const getLayout = Component.getLayout || ((page) => page)
        const sidebarType = Component.sidebarType
            ? Component.sidebarType(pageProps)
            : 'DEFAULT'
        const HeaderComponent = Component.HeaderComponent?.(pageProps)
        return (
            <AppContextProvider
                offerRedirectUrl="/offer-redirect"
                url={asPath}
                isHomeTeamFirst={false}
            >
                <NProgress />
                <StateTransfer pageUrl={asPath} />
                <LanguageHandler>
                    <AnalyticsContainer
                        pageAnalytics={pageProps.pageAnalytics}
                        countryCode={pageProps.countryCode}
                        stateCode={pageProps.stateCode}
                        cookies={pageProps.cookies}
                    >
                        <TranslationContextProvider>
                            <MetaContainer
                                appJsonLd={pageProps.appJsonLd}
                                pageJsonLd={pageProps.jsonLd}
                            />
                            <InternalLinkProvider
                                value={{
                                    mode: 'next',
                                    // @ts-expect-error the component is cloned internally and doesn't need the Href
                                    InternalLink: <Link></Link>,
                                }}
                            >
                                <CookieCompliance script="//consent.trustarc.com/notice?domain=pickwise.com&c=teconsent&js=nj&noticeType=bb&text=true" />
                                <Navigation path={asPath} />
                                <AdContainer enabled={Component.enableAds} />
                                <Grid withSidebar={!!sidebarType}>
                                    {getLayout(
                                        <Component {...pageProps} />,
                                        pageProps
                                    )}
                                    {sidebarType && (
                                        <Sidebar
                                            sidebarType={sidebarType}
                                            HeaderComponent={HeaderComponent}
                                        />
                                    )}

                                    <FontLoader />
                                </Grid>
                                <Footer />
                            </InternalLinkProvider>
                        </TranslationContextProvider>
                    </AnalyticsContainer>
                </LanguageHandler>
            </AppContextProvider>
        )
    }
}

export default nextReduxWrapper.withRedux(WrappedApp)
