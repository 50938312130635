
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UseNavigationHamburger = {
  navigationHamburger: AppState['navigationHamburger'],
  navigationHamburgerLoading: AppState['navigationHamburgerLoading'],
  navigationHamburgerError: AppState['navigationHamburgerError'],
  getNavigationHamburger: (data:RequestTypes['getNavigationHamburger'], callbacks?:Callbacks)=>void,
}

export default function useNavigationHamburger():UseNavigationHamburger {
  const {
    navigationHamburger, navigationHamburgerLoading, navigationHamburgerError } = useSelector((state:AppState)=>({
    navigationHamburger: state.navigationHamburger,
    navigationHamburgerLoading: state.navigationHamburgerLoading,
    navigationHamburgerError: state.navigationHamburgerError,
  }));
  const dispatch = useDispatch();
  const getNavigationHamburger = useCallback((data:RequestTypes['getNavigationHamburger'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getNavigationHamburger(data, callbacks))
  },[dispatch])
  return {
    navigationHamburger,
    navigationHamburgerLoading,
    navigationHamburgerError,
    getNavigationHamburger,
  }
}
