import { useEffect, useRef } from 'react'
export const useIsMounted = (): React.RefObject<boolean> => {
    const isMounted = useRef(true)
    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    return isMounted
}
