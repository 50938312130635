
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UsePromoCodesSidebar = {
  promoCodesSidebar: AppState['promoCodesSidebar'],
  promoCodesSidebarLoading: AppState['promoCodesSidebarLoading'],
  promoCodesSidebarError: AppState['promoCodesSidebarError'],
  getPromoCodesSidebar: (data:RequestTypes['getPromoCodesSidebar'], callbacks?:Callbacks)=>void,
}

export default function usePromoCodesSidebar():UsePromoCodesSidebar {
  const {
    promoCodesSidebar, promoCodesSidebarLoading, promoCodesSidebarError } = useSelector((state:AppState)=>({
    promoCodesSidebar: state.promoCodesSidebar,
    promoCodesSidebarLoading: state.promoCodesSidebarLoading,
    promoCodesSidebarError: state.promoCodesSidebarError,
  }));
  const dispatch = useDispatch();
  const getPromoCodesSidebar = useCallback((data:RequestTypes['getPromoCodesSidebar'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getPromoCodesSidebar(data, callbacks))
  },[dispatch])
  return {
    promoCodesSidebar,
    promoCodesSidebarLoading,
    promoCodesSidebarError,
    getPromoCodesSidebar,
  }
}
