import { FC } from 'react'
import { Icon, Link } from '@ssgat/react-components'
import { footerProps } from './Footer.types'
import styles from './Footer.module.scss'
import Strings from 'project/localization'

const FOOTER_TERMS = Strings.footerTerms
const FOOTER_LINKS = [
    {
        url: '/terms-conditions',
        title: Strings.termsAndConditions,
    },
    {
        url: '/privacy-policy',
        title: Strings.privacyPolicy,
    },
    {
        url: '/contact-us',
        title: Strings.contactUs,
    },
]
const SOCIAL_LINKS = {
    facebook: 'https://www.facebook.com/groups/pickswise',
    instagram: 'https://www.instagram.com/pickswise',
    twitter: 'https://twitter.com/Pickswise',
    youtube: 'https://www.youtube.com/channel/UCUTl3dCRjdWHrag4qF0HXfg',
}

export const Footer: FC<footerProps> = ({
    footerLinks = FOOTER_LINKS,
    terms = FOOTER_TERMS,
    socialLinks = SOCIAL_LINKS,
}) => {
    const year = new Date().getFullYear()
    return (
        <footer className={styles.footer}>
            <div className={styles.footerInner}>
                <img
                    className={styles.logo}
                    src="/logos/pw-logo.png"
                    loading="lazy"
                    alt={Strings.pickswiseLogo}
                />
                <div className={styles.socialLinks}>
                    {socialLinks.youtube && (
                        <Link
                            className={styles.socialIcon}
                            href={socialLinks.youtube}
                        >
                            <Icon
                                icon="icon-filled-youtube"
                                variant="secondary"
                            />
                        </Link>
                    )}

                    {socialLinks.facebook && (
                        <Link
                            className={styles.socialIcon}
                            href={socialLinks.facebook}
                        >
                            <Icon
                                icon="icon-filled-facebook"
                                variant="secondary"
                            />
                        </Link>
                    )}
                    {socialLinks.twitter && (
                        <Link
                            className={styles.socialIcon}
                            href={socialLinks.twitter}
                        >
                            <Icon
                                icon="icon-filled-twitter"
                                variant="secondary"
                            />
                        </Link>
                    )}
                    {socialLinks.instagram && (
                        <Link
                            className={styles.socialIcon}
                            href={socialLinks.instagram}
                        >
                            <Icon
                                icon="icon-filled-instagram"
                                variant="secondary"
                            />
                        </Link>
                    )}
                </div>
                <div className={styles.termsContainer}>
                    <p className={styles.terms}>{terms}</p>
                    <hr />
                    <ul className={styles.links}>
                        {footerLinks &&
                            footerLinks.map(({ url, title }, i) => {
                                return (
                                    <li
                                        className={styles.linksListItem}
                                        key={`${i}-footer-link`}
                                    >
                                        <Link href={url}>{title}</Link>
                                    </li>
                                )
                            })}
                        <li
                            className={styles.linksListItem}
                            id="teconsent"
                        ></li>
                    </ul>
                    <hr />
                </div>

                <div className={styles.ssgAndWarning}>
                    <div className={styles.ageWarning}>21+</div>
                    <Link
                        href="https://www.spotlightsportsgroup.com/"
                        className={styles.ssgLogo}
                    >
                        <img
                            src="/logos/ssg-logo.png"
                            loading="lazy"
                            alt={Strings.ssgLogo}
                        />
                    </Link>
                </div>

                <p className={styles.copyright}>Copyright &copy; {year}</p>
            </div>
        </footer>
    )
}
