import { useEffect } from 'react'
import Router from 'next/router'
import { useIsMounted } from './useIsMounted'

export const useOnAfterRouteChange = (fn: () => void, leading = false) => {
    const isMounted = useIsMounted()

    const handleRouteChange = function () {
        isMounted.current && fn()
    }

    useEffect(() => {
        Router.events.on('routeChangeComplete', handleRouteChange)
        leading && handleRouteChange()
        return () => Router.events.off('routeChangeComplete', handleRouteChange)
        // It's safer to assume that fn isn't memoised as this could cause spamming event listeners
        //eslint-disable-next-line
    }, [])
}
