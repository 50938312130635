import { each } from "lodash";
import { Constants } from "./utils/constants";
import { LocalizedStringsMethods } from "./string-types";

export type LanguageContent = typeof defaultContent;

export type LocalizedStrings = LocalizedStringsMethods & LanguageContent;

type StringsSingleton = {
  instance: LocalizedStrings | undefined;
};

const defaultContent = {
  daysShort: "Days",
  now: "Now",
  hourShort: "h",
  hoursShort: "h",
  minuteShort: "m",
  version: "Version",
  editors: "Editor's",
  firebaseID: "Copy Firebase ID",
  min: "Min",
  no: "No.",
  claim: "Claim",
  computerPicksAndPredictions: "Computer Model Picks and Predictions",
  downloadProduction: "Download Production App",
  alreadyUpToDate: "You are already up to date",
  downloadStaging: "Download Staging App",
  reasoning: "Reasoning",
  featuredIn: "Featured In",
  join: "Join",
  joinCustomerPanel: "Join Our Customer Panel",
  pushNotifications: "Push Notifications",
  defaultErrorMessage: "An unexpected error has occurred",
  gatewayTimeoutError: "API is unreachable right now",
  get: "Get",
  exclusive: "exclusive",
  freeSports: "free sports picks to your email! Sign up below.",
  enterEmail: "Enter your email",
  selectState: "Select State",
  signUpNow: "Sign Up Now",
  iAmOver21: "I am over 21",
  iReside: "and reside in a legal gambling state",
  bySigning: "By signing up you agree to our",
  terms: "terms",
  termsAndConditions: "Terms & Conditions",
  setNotifications:
    "Set notifications in the settings on your device to receive alerts.",
  and: "and",
  privacyPolicy: "Privacy Policy",
  setWager: "Set Wager",
  selectOdds: "Select Odds",
  analysis: "Analysis",
  analysisDescription: "Discover the latest news across multiple sports.",
  sportAnalysis: "{0} Analysis",
  bestPick: "Best Pick",
  moneylinePick: "Moneyline Pick",
  today: "Today",
  ourAnalysis: "Our Reasoning",
  expertsAnalysis: "Expert Reasoning",
  guestExpert: "Guest Expert",
  guestPick: "Guest Pick",
  viewPrediction: "View Prediction",
  mins: "Mins",
  filterPicks: "Filter Picks",
  betTypes: "Bet Types",
  experts: "Experts",
  expertsOverview: "Expert's Overview",
  relatedGameProps: "Related game props",
  confidenceRating: "Confidence Rating",
  sortPicks: "Sort Picks",
  filter: "Filter",
  final: "Final",
  nextUp: "Next Up",
  nextGames: "Next Games",
  top: "Top",
  news: "News",
  bestBets: "Best Bets",
  seeAll: "See All",
  seeAllWithNumber: "See All ({0})",
  topNews: "News",
  seeAllProps: "See All Props",
  seeBio: "See Bio",
  hideBio: "Hide Bio",
  picks: "Picks",
  sportPicksCount: "{0} Picks ({1})",
  sportBestBets: "{0} Best Bets",
  sportParlays: "{0} Parlays",
  sportPredictions: "{0} Predictions",
  offSeason: "Off Season",
  parlayPicks: "Parlay Picks",
  applyFilters: "Apply Filters",
  clearAll: "Clear All",
  sortBy: "Sort By",
  sort: "Sort",
  back: "Back",
  retry: "Retry",
  screenError: "Oops, Something's Gone Wrong.\nPlease Try Again.",
  webError: "Oops, something’s gone wrong. Please try again, or head to the Home page.",
  noPicksAvailableTitle: "Sorry, There Are No Picks Available At This Time!",
  noPicksAvailableBody: "In the meantime, tap over to the {0} for our favorite plays of the day.",
  home: "Home",
  homeTab: "Home tab",
  sports: "Sports",
  bonuses: "Bonuses",
  bonusesDescription: "Check out the latest selection of offers from your favorite Sportsbooks.",
  more: "More",
  parlays: "Parlays",
  props: "Props",
  propBets: "Prop Bets",
  predictions: "Predictions",
  otherGames: "Other Games",
  share: "Share",
  guestExpertPredictions: "Guest Expert Predictions",
  guestExperts: "Guest Experts",
  pickswiseExperts: "Pickswise Experts",
  comingSoon: "{0} Coming Soon!",
  topTrends: "Top Trends",
  modelProjections: "Model Projections",
  pickswisePredictions: "Pickswise Predictions",
  pickswiseExpertsPredictions: "Pickswise Expert Predictions",
  pickswiseLogo: "Pickswise Logo",
  payout: "Payout",
  placeBet: "Place Bet",
  signUp: "Sign Up",
  futures: "Futures",
  noMatchingPicks:
    "Sorry, your filter choices don't have any matching Picks at this time",
  tryChangingFilters: "Try changing one or more of the filters above.",
  loadMoreStories: "Load More Articles",
  relatedArticles: "Related Articles",
  lastUpdated: "Last updated:",
  playableTo: "Available at time of publishing, playable to ",
  readMore: "Read More",
  bonusOffers: "Bonus Offers",
  offers: "Offers",
  saferGambling: 'Safer Gambling',
  gamblingMessage: 'Pickswise supports responsible gambling.',
  usa: 'https://www.ncpgambling.org/ 1-800-522-4700',
  uk: 'https://www.begambleaware.org/ 0808 8020 133',
  can: 'https://www.ncpgambling.org/help-treatment/national-helpline-1-800-522-4700/',
  aus: 'https://www.gamblinghelponline.org.au/',
  mex: 'http://www.juegosysorteos.gob.mx/es/Juegos_y_Sorteos/Atencion_al_Ludopata',
  contactUs: "Contact Us",
  ssgLogo: "SSG logo",
  twitter: "Twitter",
  selectCategory: "Select Category",
  selectGame: "Select Game: {0}",
  eventVideo: "{0} Video",
  footerTerms: `If you or someone you know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER (1-800-426-2537) (IL).\n
      Gambling problem? Call 1-800-GAMBLER (MI/NJ/PA/WV/WY), 1-800-9-WITH-IT (IN), 1-800-522-4700 (CO), 1-800-BETS OFF (IA), 1-888-532-3500 (VA), 1-800-NEXT STEP (AZ), or call/text TN REDLINE 1-800-889-9789 (TN).\n
      21+ (18+ WY). AZ/CO/IL/IN/IA/MI/NJ/PA/TN/VA/WV/WY only. Eligibility restrictions apply. T&C's Apply. Void where prohibited.`,
};

const strings: StringsSingleton = {
  instance: undefined,
};

const getStrings: () => LocalizedStrings = () => {
  if (!strings.instance) {
    throw new Error(
      "Strings is not initialized. Make sure Web or Mobile sets strings.instance"
    );
  }
  return strings.instance;
};

const setStrings = (stringsParam: LocalizedStrings) => {
  strings.instance = stringsParam;
  initBlobby();
};

const stringRecords: Record<string, LanguageContent> = {
  en: defaultContent,
  blobby: defaultContent,
};

const initBlobby = () => {
  if (Constants.simulate.FORCE_LANGUAGE === "blobby") {
    const blobby = {} as LanguageContent;
    each(stringRecords.en, (val, key) => {
      const words = val.split(" ");
      const newWordsBlobby = words.map((word: string) => {
        const arr = ["eeeee", "blob", "blobby", "wuueeeeh"];
        const random = Math.floor(Math.random() * (1 + (arr.length - 1)));
        if (word.indexOf("{") !== -1) {
        // reserve params
          return word;
        }
        return arr[random];
      });
      blobby[(key as keyof LanguageContent)] = `${newWordsBlobby.join(" ").trim()}`;
    });
    stringRecords.blobby = blobby;
  }
};

export { stringRecords, getStrings, setStrings };
