import React, { useEffect, useRef } from 'react'
import { addClass } from '@ssgat/react-components'
import { AdProps } from './Ad.types'
import styles from './Ad.module.scss'

declare global {
    interface Window {
        googletag: any
    }
}

const Ad: React.FC<AdProps> = ({ slot, id, size, className }) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        let slotInstance: any
        window.googletag = window.googletag || { cmd: [] }
        // bail if we ain't got these
        if (!!slot && !!id && !!size) {
            window.googletag.cmd.push(() => {
                slotInstance = window.googletag
                    .defineSlot(slot, size, id)
                    .addService(window.googletag.pubads())
                window.googletag.pubads().enableSingleRequest()
                window.googletag.enableServices()
            })
            window.googletag.cmd.push(() => {
                window.googletag.display(id)
                ref.current && ref.current.classList.add(styles.loaded)
            })
        }

        return () => {
            window.googletag.cmd.push(() => {
                window.googletag.destroySlots([slotInstance])
            })
        }
    }, [size, id, slot])
    // bail on rendering if we haven't got these essential props
    if (!(size && id && slot)) {
        return null
    }
    return (
        <div
            data-testid="GoogleAd"
            className={addClass(styles.adWrap, className || '')}
            ref={ref}
            style={
                {
                    '--width': `${size[0]}px`,
                    '--height': `${size[1]}px`,
                } as React.CSSProperties
            }
        >
            <ins id={id}></ins>
        </div>
    )
}

export default Ad
